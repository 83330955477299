import { useState } from 'react';
import { Button } from 'reactstrap';
import { downloadMultipleQuestions } from '../../../actions/assurance';
import { useAppDispatch } from '../../../reducers';
import { AnalyticsEvents } from '../../../services/analytics/AnalyticsEvents';
import { getAnalytics } from '../../../services/analytics/AnalyticsService';
import { addSiteAlert } from '../../../slice/siteAlertsSlice';
import { OrganizationAssurancePortfolio } from '../../../types/assurance';
import { BlockingLoader } from '@g17eco/atoms/loader';
import { BulkActionToolbarSelectedUtrv } from '../../survey-question-list/partials/BulkActionToolbar';

interface DownloadButtonProps {
  assurancePortfolio: OrganizationAssurancePortfolio;
  selectedQuestions: BulkActionToolbarSelectedUtrv[];
}

export const DownloadButton = ({ selectedQuestions, assurancePortfolio }: DownloadButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const questionIds = selectedQuestions.map((question) => question._id);
  const dispatch = useAppDispatch();
  const portfolioBundleDownload = (_id: string) => {
    setIsLoading(true);
    return downloadMultipleQuestions(_id, { questionIds })
      .then(async ([document]) => {
        setIsLoading(false);
        if (!document || !document.url) {
          return;
        }
        window.open(document.url, '_blank', '');
        const analytics = getAnalytics();
        return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
          assurancePortfolioId: _id,
          initiativeId: assurancePortfolio.initiativeId,
          source: 'survey_assurance',
        });
      })
      .catch((e: Error) => {
        setIsLoading(false);
        dispatch(
          addSiteAlert({
            content: e.message,
            timeout: 5000,
          }));
      });
  };
  return (
    <>
      {isLoading ? <BlockingLoader /> : null}
      <Button className='ml-2 px-3' outline onClick={() => portfolioBundleDownload(assurancePortfolio._id)}>
        <i className='fa-regular fa-file-zipper text-ThemeIconSecondary mr-2' />Download data and evidence
      </Button>
    </>
  );
};
