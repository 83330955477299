import { ColumnDef, Table } from '@g17eco/molecules/table';
import { UserWithInfo, Workgroup } from '@g17eco/types/workgroup';
import { useState } from 'react';
import { Input } from 'reactstrap';
import { AddUserBtn } from './AddUserBtn';
import IconButton from '@components/button/IconButton';
import { useRemoveWorkgroupUserMutation } from '@api/workgroups';
import { generateErrorToast, generateToast } from '@components/toasts';
import { Loader } from '@g17eco/atoms/loader';

interface Props {
  workgroup: Workgroup<UserWithInfo>;
  initiativeId: string;
}

export const ManageUsers = ({ workgroup, initiativeId }: Props) => {
  const [search, setSearch] = useState('');
  const users = workgroup.users.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase())
  );

  const [removeWorkgroupUser, { isLoading: isRemovingUser }] = useRemoveWorkgroupUserMutation();
  const handleRemove = (userId: string) => {
    removeWorkgroupUser({ initiativeId, workgroupId: workgroup._id, userId })
      .unwrap()
      .then(() => {
        generateToast({
          title: 'User removed',
          color: 'success',
          message: 'User has been removed from the workgroup',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      });
  };

  const columns: ColumnDef<UserWithInfo>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Email address',
      accessorKey: 'email',
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        return (
          <div className='text-right'>
            <IconButton
              icon='fal fa-trash text-danger'
              color='link'
              onClick={() => handleRemove(row.original._id)}
              disabled={isRemovingUser}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isRemovingUser ? <Loader /> : null}
      <div className='d-flex gap-3 justify-content-between mt-5 mb-3'>
        <Input placeholder='Search for user' value={search} onChange={(e) => setSearch(e.target.value)} />
        <AddUserBtn workgroup={workgroup} initiativeId={initiativeId} />
      </div>
      <Table data={users} columns={columns} pageSize={10} responsive noData={'No users added to the workgroup'} />
    </>
  );
};
