import { useGetAIUtrvFurtherNotesDraftMutation } from '@api/ai';
import { CopyButton } from '@features/copy';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { useEffect } from 'react';
import { QueryError } from '@components/query/QueryError';
import { SectionLoader } from '@g17eco/atoms/loader';

interface Props {
  utrv: SurveyModelMinimalUtrv;
}
export const FurtherNotes = ({ utrv }: Props) => {
  const [getFurtherNotesDraft, { isLoading, data, isError, error }] = useGetAIUtrvFurtherNotesDraftMutation();
  const furtherNotes = data?.content ?? '';
  useEffect(() => {
    getFurtherNotesDraft({ initiativeId: utrv.initiativeId, data: { utrvId: utrv._id, draftData: utrv } });
  }, [getFurtherNotesDraft, utrv]);
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 className='my-0 text-ThemeHeadingDark'>AI Draft Further Explanation/Notes:</h6>
        <CopyButton content={furtherNotes} />
      </div>
      {isError ? <QueryError error={error} type={'danger'} /> : null}
      {isLoading ? <SectionLoader /> : <p className='text-ThemeTextMedium'>{furtherNotes}</p>}
    </div>
  );
};
