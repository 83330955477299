import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { DefaultSurveyConfig } from '@components/company-settings';
import { useAppSelector } from '@reducers/index';
import { currentInitiative, getRootOrg } from '@selectors/initiative';
import { InitiativeDetails } from '@components/company-settings/InitiativeDetails';
import { BlockingLoader } from '@g17eco/atoms/loader';
import { InitiativeData, RootInitiativeData } from '@g17eco/types/initiative';
import { CTAdminBreadcrumbs } from '../components/breadcrumbs/CTAdminBreadcrumbs';
import { ROUTES } from '@constants/routes';
import { CompanySettingsPages } from '../company-tracker-types';
import { SURVEY } from '@constants/terminology';
import { AccountSettingsContainer } from '../components/account-settings/AccountSettingsContainer';
import { SubscriptionInfo } from '../components/subscription-info';
import { NavTab } from '@components/company-settings/Navigation';

interface PageProps {
  page: string;
  rootOrg: RootInitiativeData;
  initiative: InitiativeData;
}

const Page = ({ page, rootOrg, initiative }: PageProps) => {
  switch (page) {
    case CompanySettingsPages.ReportSettings:
      return <DefaultSurveyConfig />;
    case CompanySettingsPages.AccountManagement:
      return <SubscriptionInfo initiativeId={initiative._id} rootOrg={rootOrg} />;
    case CompanySettingsPages.Details:
    default:
      return <InitiativeDetails rootOrg={rootOrg} initiative={initiative} bankingRoute={ROUTES.BANKING_SETTINGS} />;
  }
};

export const CompanySettingsRoute = () => {
  const { path } = useRouteMatch();
  const { page = 'detail' } = useParams<{ page?: string }>();

  const rootOrg = useAppSelector(getRootOrg);
  const initiative = useAppSelector(currentInitiative);
  const isRootOrg = rootOrg && rootOrg._id === initiative?._id;

  const navTabs: NavTab[] = [
    {
      code: CompanySettingsPages.Details,
      label: 'Account details',
    },
    {
      code: CompanySettingsPages.AccountManagement,
      label: 'Account management',
      hidden: !isRootOrg,
    },
    {
      code: CompanySettingsPages.ReportSettings,
      label: `${SURVEY.CAPITALIZED_SINGULAR} settings`,
    },
  ];

  return (
    <Switch>
      <Route path={path}>
        {!rootOrg || !initiative ? (
          <BlockingLoader />
        ) : (
          <AccountSettingsContainer
            BreadCrumbsComponent={CTAdminBreadcrumbs}
            initiative={initiative}
            navTabs={navTabs}
            page={page}
            settingRoute={ROUTES.ACCOUNT_SETTINGS}
          >
            <Page page={page} initiative={initiative} rootOrg={rootOrg} />
          </AccountSettingsContainer>
        )}
      </Route>
    </Switch>
  );
};
