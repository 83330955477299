import { useEffect, useState } from 'react';
import G17Client from '@services/G17Client';
import { BlockingLoader } from '@g17eco/atoms/loader';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DATE, formatDateUTC } from '@utils/date';
import { BasicAlert } from '@g17eco/molecules/alert';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

const LEVEL = {
  WARNING: 'warning',
  ERROR: 'error',
};

interface UtrvProofProps {
  isOpen: boolean;
  toggle: () => void;
  utrvId: string;
}

interface HistoryStatus {
  id: string;
  verified: boolean;
  date: string;
  error?: string;
}

interface LedgerServiceValidatorResponse {
  ledger?: {
    verified: boolean;
    error?: string;
  };
  ethereum?: {
    verified: boolean;
    error?: string;
  };
  utrv?: {
    id: string;
    verified: boolean;
    history: HistoryStatus[];
    error?: string;
  };
}

export const UtrvProof = (props: UtrvProofProps) => {
  const { utrvId, toggle } = props;
  const [proof, setProof] = useState<LedgerServiceValidatorResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    G17Client.getProofByUtrvId(utrvId)
      .then((proof) => {
        setProof(proof);
      })
      .catch(() => setErrored(true))
      .finally(() => setIsLoading(false));
  }, [utrvId]);

  if (isLoading) {
    return <BlockingLoader />;
  }

  if (errored) {
    return (
      <Modal className='modal-lg' isOpen={true} toggle={toggle} backdrop='static'>
        <ModalHeader toggle={toggle}>Ledger Consistency Check</ModalHeader>
        <ModalBody>
          <BasicAlert type={'danger'}>Unable to check the ledger consistency. Please try again later.</BasicAlert>
        </ModalBody>
      </Modal>
    );
  }

  const history = proof?.utrv?.history ?? [];
  const latestValue = proof?.utrv?.history[history.length - 1];

  const dataChecks = [
    {
      label: 'Ledger status',
      level: LEVEL.ERROR,
      value: proof?.ledger?.verified ? 'The ledger is working as expected' : 'Unable to get ledger status',
      check: Boolean(proof?.ledger?.verified),
    },
    {
      label: 'Ethereum match',
      level: LEVEL.WARNING,
      value: proof?.ethereum?.verified
        ? 'The ledger digest matches to Ethereum'
        : 'The ledger digests do not match Ethereum',
      check: Boolean(proof?.ethereum?.verified),
    },
    {
      label: 'Latest value verification',
      level: LEVEL.ERROR,
      value: latestValue?.verified ? 'The latest values match the ledger' : 'The latest values do not match the ledger',
      note: 'Note: It can take up to 10 mins after a value is updated before the ledger can validate.',
      check: Boolean(latestValue?.verified),
    },
    {
      label: 'Full provenance validation',
      level: LEVEL.WARNING,
      value: proof?.utrv?.verified ? 'The full provenance is consistent' : 'The full provenance is not consistent',
      check: Boolean(proof?.utrv?.verified),
      children: (
        <div className='d-flex flex-column'>
          {history.map((h, i) => (
            <div key={h.id}>
              <i className={`fa-2x fa ${h.verified ? 'fa-check text-success' : 'fa-times text-danger'} mr-1`} />
              {formatDateUTC(h.date, DATE.DEFAULT_SPACES_WITH_TIME)} UTC
            </div>
          ))}
        </div>
      ),
    },
  ];

  const overallCheck = dataChecks.filter((d) => d.level === LEVEL.ERROR).every((d) => d.check === true);

  const getIcon = (d: { level: string; check: boolean }) => {
    if (d.check) {
      return 'fa-check-circle text-ThemeSuccessMedium';
    }
    if (d.level === LEVEL.WARNING) {
      return 'fa-question-circle text-ThemeAccentMedium';
    }
    return 'fa-times-circle text-ThemeDangerMedium';
  };

  return (
    <Modal className='modal-lg' isOpen={true} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Ledger Consistency Check</ModalHeader>
      <ModalBody>
        <BasicAlert type={overallCheck ? 'success': 'danger'}>
          <span className='mr-2'>Current consistency of the data against the ledger:</span>
          {overallCheck ? (
            <SimpleTooltip text='All data is correctly matched against an independent public ledger that guarantees it has not been manipulated.'>
              <span className='badge background-ThemeSuccessMedium text-ThemeTextWhite'>OK</span>
            </SimpleTooltip>
          ) : (
            <SimpleTooltip text='There is a difference between G17Eco and the public Ledger. Please notify Customer Support as soon as possible.'>
              <span className='badge background-ThemeDangerMedium text-ThemeTextWhite'>Mismatch</span>
            </SimpleTooltip>
          )}
        </BasicAlert>
        <div className='d-flex mt-3 flex-column'>
          {dataChecks.map((d, i) => (
            <div key={`${d.label}-${i}`} className='d-flex align-items-center p-3 border-bottom border-ThemeBgDisabled'>
              <div>
                <i className={`fa ${getIcon(d)} fa-2x mr-3`} />
              </div>
              <div>
                <div>
                  <strong>{d.label}</strong>
                </div>
                <div>{d.value}</div>
                <div className='text-ThemeTextPlaceholder'>{d.note}</div>
                {d.children ? <div>{d.children}</div> : null}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
