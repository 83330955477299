import { CopyButton } from '@features/copy';
import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { SectionLoader } from '@g17eco/atoms/loader';
import { badgeColorMap, getAnswerTooltip, getChangePercentage, getNumberAnswer, getValueEvaluation } from '../utils';
import { Badge } from 'reactstrap';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';

export interface BaseAnswerProps {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation'>;
  hasPreviousUtrvs: boolean;
  inputAnswer: string | number | undefined;
  predictedAnswer: string | number;
  targetValue?: number;
  isFetching?: boolean;
}

export const TextAnswer = ({
  inputAnswer,
  predictedAnswer,
  isFetching,
}: Pick<BaseAnswerProps, 'inputAnswer' | 'predictedAnswer' | 'isFetching'>) => {
  const answer = inputAnswer ? String(inputAnswer) : String(predictedAnswer);
  const header = inputAnswer ? 'Answer' : 'AI Draft Answer';
  return (
    <div className=''>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 className='my-0 text-ThemeHeadingDark'>{header}:</h6>
        <CopyButton content={answer} />
      </div>
      {isFetching ? <SectionLoader /> : <p className='text-ThemeTextMedium'>{answer}</p>}
    </div>
  );
};

const Target = ({
  utr,
  targetValue,
  answer,
  unitDesc,
}: Required<Pick<BaseAnswerProps, 'utr' | 'targetValue'>> & { answer: number; unitDesc: string }) => {
  const { targetDirection = 'increase' } = utr;
  const valueEvaluation = getValueEvaluation({ value: answer, targetValue, targetDirection });
  const badgeColor = badgeColorMap[valueEvaluation];

  const changePercentage = getChangePercentage({ answer, targetValue });
  return (
    <div className='mt-2 d-flex justify-content-end align-items-center'>
      <span className={`text-sm question-assistant__ai__target--${valueEvaluation}`}>
        Target: {targetValue} {unitDesc}
      </span>
      <Badge color={badgeColor} className='ml-2' pill>
        <i className={`mr-1 fa-solid fa-arrow-trend-${targetDirection === 'decrease' ? 'down' : 'up'}`} />
        {changePercentage}%
      </Badge>
    </div>
  );
};

export const NumberAnswer = ({
  utr,
  inputAnswer,
  hasPreviousUtrvs,
  predictedAnswer,
  targetValue,
  isFetching,
  selectedColumnCode,
}: BaseAnswerProps & { inputAnswer: number | undefined; selectedColumnCode?: string }) => {
  const { hasAnswered, answer } = getNumberAnswer({ inputAnswer, predictedAnswer });

  const header = hasAnswered ? 'Answer' : 'Answer Estimate';
  const unitDesc = getMetricUnitDesc(utr, selectedColumnCode, '');

  if (isNaN(answer)) {
    return <UnsettledAnswer />;
  }

  return (
    <div className='mb-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <h6 className='my-0 text-ThemeHeadingDark'>{header}:</h6>
          {hasAnswered ? null : (
            <SimpleTooltip text={getAnswerTooltip(hasPreviousUtrvs)}>
              <i className='fa-light fa-circle-info ml-1 text-ThemeIconSecondary' />
            </SimpleTooltip>
          )}
        </div>
        {isFetching ? null : (
          <p className='mb-0 text-ThemeTextDark fw-bold'>
            {answer} {unitDesc}
          </p>
        )}
      </div>
      {isFetching ? <SectionLoader /> : null}
      {targetValue === undefined ? null : (
        <Target utr={utr} targetValue={targetValue} answer={answer} unitDesc={unitDesc} />
      )}
    </div>
  );
};

export const UnsettledAnswer = () => {
  return <p className='text-ThemeTextMedium'>No answer can be suggested</p>;
};
