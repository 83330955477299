import { GetSecondaryConnectionsResponse, useGetSecondaryConnectionsQuery } from '@api/utrv';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { QUESTION } from '@constants/terminology';
import { DashboardDivider } from '@g17eco/atoms/divider';
import { UniversalTrackerPlain } from '@g17eco/types/universalTracker';
import { useToggle } from '@hooks/useToggle';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UtrvConnection } from './types';
import { useConnectionContext } from './ConnectionContext';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { getConnectionValue, getInputValue, getUtrvConnections } from './utils';
import Eyes from '../../../images/eyes.gif';
import { getFormattedSurveyDate } from '@utils/survey';
import { ConnectionsGroup } from './ConnectionsGroup';
import React from 'react';

const WarningModal = ({
  open,
  toggle,
  handleConfirm,
}: {
  open: boolean;
  toggle: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeWarningExtraDark'>Replace answer?</span>
      </ModalHeader>
      <ModalBody>
        <p className='mb-0'>
          This field already has a value/content in it. If you proceed this value will be replaced with the answer you
          have selected in the sidebar.
        </p>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={toggle}>
          Cancel
        </Button>
        <Button color='warning' onClick={handleConfirm}>
          Replace answer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation' | 'unitType' | 'unit'>;
  utrv: SurveyModelMinimalUtrv;
}

export const Connections = ({ utr, utrv }: Props) => {
  const getSecondaryConnectionsQuery = useGetSecondaryConnectionsQuery({ utrvId: utrv._id });
  const isStaffUser = useAppSelector(isStaff);

  const { connection: selectedConnection, setConnection, setInputData, currentInputData } = useConnectionContext();
  const [showWarning, toggleShowWarning] = useToggle(false);

  const handleClickConnection = (connection: UtrvConnection) => setConnection(connection);

  const handlePopulateInput = (connection: UtrvConnection) => {
    setConnection(connection);

    const currentValue = getInputValue({ utr, currentInputData, valueListCode: connection.valueListCode });
    const connectionValue = getConnectionValue({
      currentInputData,
      connection,
      utr,
      utrv,
    });

    if (currentValue === connectionValue) {
      return;
    }

    if (currentValue === undefined) {
      setInputData(connectionValue);
      return;
    }

    toggleShowWarning();
  };

  const handleConfirm = () => {
    if (!selectedConnection) {
      setInputData(undefined);
      toggleShowWarning();
      return;
    }

    const connectionValue = getConnectionValue({
      currentInputData,
      connection: selectedConnection,
      utr,
      utrv,
    });
    setInputData(connectionValue);
    toggleShowWarning();
  };

  const onSuccessRender = ({ connections, utrs, integrationUtrs = [], surveys }: GetSecondaryConnectionsResponse) => {
    const { surveyIdToConnectionsMap, integratedConnections, allConnections } = getUtrvConnections(connections);

    if (!allConnections.length) {
      return (
        <div className='d-flex flex-column align-items-center gap-3'>
          <img src={Eyes} alt={'no-connections'} width={120} />
          <p className='text-lg text-ThemeTextMedium text-center'>
            We looked, but this {QUESTION.SINGULAR} currently has no connections
          </p>
        </div>
      );
    }

    return (
      <>
        <p className='text-sm text-ThemeTextLight'>
          Click <i className='fa-light fa-arrow-right-to-arc' /> to populate input field with selected answer
        </p>
        {integratedConnections.length ? (
          <ConnectionsGroup
            title='Emissions Tracker'
            connections={integratedConnections}
            integrationUtrs={integrationUtrs}
            utrs={[]}
            utr={utr}
            selectedConnection={selectedConnection}
            onClickConnection={handleClickConnection}
            onPopulateInput={handlePopulateInput}
            displayDetails={isStaffUser}
          />
        ) : null}
        {surveys.map((survey, index) => {
          const connections = surveyIdToConnectionsMap.get(survey._id) ?? [];
          if (!connections.length) {
            return null;
          }

          return (
            <React.Fragment key={survey._id}>
              {index ? <DashboardDivider className='my-3' /> : null}
              <ConnectionsGroup
                title={getFormattedSurveyDate(survey)}
                connections={connections}
                integrationUtrs={[]}
                utrs={utrs}
                utr={utr}
                selectedConnection={selectedConnection}
                onClickConnection={handleClickConnection}
                onPopulateInput={handlePopulateInput}
                displayDetails={isStaffUser}
                initiativeId={survey.initiativeId}
                surveyScope={survey.scope}
              />
            </React.Fragment>
          );
        })}

        <WarningModal open={showWarning} toggle={toggleShowWarning} handleConfirm={handleConfirm} />
      </>
    );
  };

  return (
    <div>
      <QueryWrapper query={getSecondaryConnectionsQuery} onSuccess={onSuccessRender} />
    </div>
  );
};
