import { PermissionsUser } from './types';
import { UserRoles } from '@constants/user';

export const hasPermission = (
  user: PermissionsUser,
  initiativeId: string,
  roles: UserRoles[],
  infiniteRecursionProtection: string[] = []
): boolean => {
  infiniteRecursionProtection.push(initiativeId);
  if (!user.permissions) {
    return false;
  }
  const initiativePermission = user.permissions.find((p) => p.initiativeId === initiativeId);
  if (initiativePermission && roles.some((role) => initiativePermission.permissions.includes(role))) {
    // Great, we have explicit permissions, early exit
    return true;
  }

  const initiativeTree = user.initiativeTree;
  if (!initiativeTree) {
    return false;
  }

  const initiative = initiativeTree.find((d) => d._id === initiativeId);
  if (!initiative?.parentId) {
    return false;
  }

  if (infiniteRecursionProtection.includes(initiative.parentId)) {
    return false;
  }

  const parent = initiativeTree.find((d) => d._id === initiative.parentId);
  if (!parent) {
    return false;
  }

  return hasPermission(user, parent._id, roles, infiniteRecursionProtection);
}