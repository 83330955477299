import { AssessmentData } from '@apps/materiality-tracker/api/materiality-assessment';
import { Loader } from '@g17eco/atoms/loader';
import { useMemo } from 'react';
import Chart from 'react-google-charts';
import { ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import './styles.scss';

const WIDTH = '100%';
const HEIGHT = 400;
const COLUMN_WIDTH = '93%';
const HEADERS = ['Topic', 'Score', { role: 'style' }];
const COLORS = {
  BORDER: '#E6E9ED',
  BASELINE: '#FFF',
  SELECTED_COLUMN: '#2971E5',
  DEFAULT_COLUMN: '#E8F1FF',
};

export const AssessmentInsightsChart = ({
  data,
  selectedTopicCode,
  columnClickHandler,
}: {
  data: AssessmentData[];
  selectedTopicCode: string;
  columnClickHandler: (index: number) => void;
}) => {
  const chartData = useMemo(() => {
    const selectedIndex = data.findIndex((topic) => topic.code === selectedTopicCode);
    return data.map((topic, index) => [topic.name, topic.score, index === selectedIndex ? COLORS.SELECTED_COLUMN : '']);
  }, [data, selectedTopicCode]);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback({ chartWrapper }) {
        const [{ row }] = chartWrapper.getChart().getSelection();
        columnClickHandler(row);
      },
    },
  ];

  return (
    <div className='assessment__insights-chart'>
      <Chart
        chartType='ColumnChart'
        data={[HEADERS, ...chartData]}
        chartEvents={chartEvents}
        loader={<Loader />}
        options={{
          bar: {
            groupWidth: COLUMN_WIDTH,
          },
          chartArea: {
            width: WIDTH,
            height: HEIGHT - 10,
            backgroundColor: {
              stroke: COLORS.BORDER,
              strokeWidth: 1,
            },
          },
          hAxis: {
            textPosition: 'none',
          },
          vAxis: {
            gridlines: {
              count: 0,
            },
          },
          baselineColor: COLORS.BASELINE,
          legend: 'none',
          colors: [COLORS.DEFAULT_COLUMN],
        }}
        width={WIDTH}
        height={HEIGHT}
      />
    </div>
  );
};
