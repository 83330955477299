import { UniversalTrackerPlain, UtrValueType } from '@g17eco/types/universalTracker';
import { PreviousUtrv } from './types';
import { Collapse } from 'reactstrap';
import { useState } from 'react';
import classNames from 'classnames';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { getNumberAnswer, getChangePercentage, getValueEvaluation } from './utils';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { SectionLoader } from '@g17eco/atoms/loader';

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection'>;
  utrv: SurveyModelMinimalUtrv;
  previousUtrvs: PreviousUtrv[];
  predictedAnswer: string | number;
  isFetching: boolean;
}

const TextPreviousAnswer = ({
  previousUtrvs,
  header,
  isFetching,
}: Pick<Props, 'previousUtrvs' | 'isFetching'> & { header: JSX.Element }) => {
  const [openingUtrv, setOpeningUtrv] = useState<PreviousUtrv | undefined>(undefined);
  return (
    <>
      {header}
      {isFetching ? (
        <SectionLoader />
      ) : (
        previousUtrvs.map((utrv) => {
          const isOpen = utrv === openingUtrv;
          return (
            <div key={utrv.effectiveDate} className='mt-1'>
              <div
                className='d-flex justify-content-between align-items-center cursor-pointer'
                onClick={() => setOpeningUtrv(isOpen ? undefined : utrv)}
              >
                <span className='text-sm text-ThemeTextMedium'>{utrv.effectiveDate}</span>
                <i
                  className={classNames('fa-light text-ThemeIconSecondary', {
                    'fa-caret-up': isOpen,
                    'fa-caret-down': !isOpen,
                  })}
                ></i>
              </div>
              <Collapse isOpen={isOpen}>
                <p className='text-ThemeTextMedium mt-1'>{utrv.inputData}</p>
              </Collapse>
            </div>
          );
        })
      )}
    </>
  );
};

const ChangePercentage = ({ utrv, utr, previousUtrvs, predictedAnswer }: Required<Props>) => {
  const { targetDirection = 'increase' } = utr;
  const lastAnswer = Number(previousUtrvs[0]?.inputData);
  const { answer } = getNumberAnswer({ inputAnswer: utrv.value, predictedAnswer });
  const valueEvaluation = getValueEvaluation({ value: answer, targetValue: lastAnswer, targetDirection });
  const changePercentage = getChangePercentage({ answer, targetValue: lastAnswer });
  return (
    <span className={`text-sm question-assistant__ai__target--${valueEvaluation}`}>
      <i className={`mr-1 fa-solid fa-arrow-trend-${targetDirection === 'decrease' ? 'down' : 'up'}`} />
      {changePercentage}%
    </span>
  );
};

const NumberPreviousAnswers = ({
  utrv,
  utr,
  previousUtrvs,
  predictedAnswer,
  header,
  isFetching,
}: Props & { header: JSX.Element }) => {
  const unitDesc = getMetricUnitDesc(utr, undefined, '');

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        {header}
        <ChangePercentage
          utrv={utrv}
          utr={utr}
          previousUtrvs={previousUtrvs}
          predictedAnswer={predictedAnswer}
          isFetching={isFetching}
        />
      </div>
      {isFetching ? (
        <SectionLoader />
      ) : (
        previousUtrvs.map((utrv) => (
          <div key={utrv.effectiveDate} className='mt-1 d-flex justify-content-between align-items-center'>
            <span className='text-sm text-ThemeTextMedium'>{utrv.effectiveDate}</span>
            <span className='text-sm text-ThemeTextMedium'>
              {utrv.inputData} {unitDesc}
            </span>
          </div>
        ))
      )}
    </>
  );
};

export const PreviousAnswers = ({ utrv, utr, previousUtrvs, predictedAnswer, isFetching }: Props) => {
  const { valueType } = utr;

  const header = <h6 className='text-ThemeHeadingDark'>Previous Answers:</h6>;

  switch (valueType) {
    case UtrValueType.Text: {
      return <TextPreviousAnswer previousUtrvs={previousUtrvs} header={header} isFetching={isFetching} />;
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return (
        <NumberPreviousAnswers
          utrv={utrv}
          utr={utr}
          previousUtrvs={previousUtrvs}
          predictedAnswer={predictedAnswer}
          header={header}
          isFetching={isFetching}
        />
      );
    }
    default: {
      return null;
    }
  }
};
