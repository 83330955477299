import React, { useCallback, useEffect, useState } from 'react';
import G17Client from '@services/G17Client';
import { getEESG } from '../outputs';
import { GenerateCharts, EsgDataSources } from './eesg-data/GenerateCharts';
import { ReportData } from '@g17eco/types/reportData';
import { Loader } from '@g17eco/atoms/loader';
import { DownloadButton } from '@components/button/DownloadButton';
import { getAnalytics } from '@services/analytics/AnalyticsService';
import { AnalyticsEvents } from '@services/analytics/AnalyticsEvents';
import { DownloadSettingModal, DownloadSettingPropType } from '@components/downloads/DownloadSettingModal';
import {
  convertToUtrvStatuses,
  downloadReportHandler,
  DownloadSettingsType,
  DownloadUtrvStatusCombined,
  DownloadXlsxOrCsv,
} from '@components/downloads/util/downloadReportHandler';
import { DownloadMultiScope, DownloadType, ScopeTotals, VisibilityStatus } from '@g17eco/types/download';
import { handleSettingsChange } from '@components/downloads/util/custom';
import { getDocVisibility, getScopeGroupData } from '../sgx-metric-report/downloadScope';
import { Scope, ScopeGroups } from '@models/surveyData';
import { reportMultiScopeData } from '../reportApi';
import { loggerMessage } from '../../../logger';
import { getScopeModules } from '@utils/survey-scope';
import { SurveyInitiative } from '@g17eco/types/survey';
import { EESG_TYPE } from '@constants/standards-frameworks';

interface Props {
  initiativeId: string;
  surveyId: string;
  downloadXlsxOrCsv?: DownloadXlsxOrCsv;
  totals?: ScopeTotals;
  defaultDownloadSettings?: Partial<DownloadSettingsType>;
}

export interface DataSource {
  loaded: boolean;
  width?: number;
  height?: number;
  chart?: string;
}

const initialDataState = {
  loaded: false,
};

export const initialDataSourcesState: EsgDataSources = {
  ghgEmissions: initialDataState,
  energyConsumption: initialDataState,
  energyIntensityRatio: initialDataState,
  waterConsumption: initialDataState,
  wasteGenerated: initialDataState,
  genderSplit: initialDataState,
  genderSplitPc: initialDataState,
  genderPayGap: initialDataState,
  averageTraining: initialDataState,
  boardGender: initialDataState,
  boardDiversity: initialDataState,
};

const ECONOMIC_EESG = 'eesg_2024_economic';
const EESG_SCOPE: Scope = {
  [ScopeGroups.Frameworks]: [EESG_TYPE],
  [ScopeGroups.Standards]: [],
  [ScopeGroups.Custom]: [],
  [ScopeGroups.Sdg]: [],
  [ScopeGroups.Materiality]: [],
};

const getEESGEconomicData = ({
  reportData,
  survey,
}: {
  reportData: ReportData[];
  survey: Pick<SurveyInitiative, 'effectiveDate' | 'unitConfig'>;
}) => [
  {
    reportData: reportData.filter((utr) => utr.universalTracker.tags?.eesg_2024?.includes(ECONOMIC_EESG)),
    effectiveDate: survey.effectiveDate,
    unitConfig: survey.unitConfig,
  },
];

export const EESGDownloadButton = ({ surveyId, initiativeId, totals, defaultDownloadSettings, ...downloadHandlers }: Props) => {
  const [downloading, setDownloading] = useState(false);
  const [questionData, setQuestionData] = useState<ReportData[]>();
  const [dataSourcesState, setData] = useState(initialDataSourcesState);
  const [isLoaded, setLoaded] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const handleCloseModal = () => setOpenSettings(false);
  const handleOpenSettings = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.blur();
    }
    setOpenSettings(true);
  };

  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsType>({
    selectedScopes: [],
    visibility: VisibilityStatus.ExcludeData,
    status: DownloadUtrvStatusCombined.All,
    ...defaultDownloadSettings,
  });

  const analytics = getAnalytics();

  const downloadDocx = async () => {
    setDownloading(true);
    try {
      const appliedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
      const statuses = convertToUtrvStatuses(appliedStatus);
      const downloadScope: DownloadMultiScope = {
        scope: EESG_SCOPE,
        visibility: getDocVisibility(downloadSettings.visibility),
        targets: true, // Include targets
        ...statuses,
      };
      const data = await reportMultiScopeData(surveyId, downloadScope);
      setQuestionData(data);
    } catch (error) {
      loggerMessage(error.message);
    }

    return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
      initiativeId,
      surveyId: surveyId,
      scopeValue: EESG_TYPE,
      source: 'downloads_page',
      type: 'docx',
    });
  };

  const updateData = useCallback(
    (key: string, data: DataSource) => {
      setData((dataSources) => ({
        ...dataSources,
        [key]: data,
      }));
    },
    [setData]
  );

  useEffect(() => {
    const allLoaded = Object.keys(dataSourcesState).every((k) => dataSourcesState[k as keyof EsgDataSources].loaded);
    setLoaded(allLoaded);
  }, [dataSourcesState]);

  useEffect(() => {
    if (isLoaded && downloading && questionData) {
      G17Client.getSurveyListItem(surveyId)
        .then(async (survey) => {
          const blueprintContributions = await G17Client.getBlueprintContributions(survey.sourceName);
          return {
            blueprintContributions,
            survey,
          };
        })
        .then(({ survey, blueprintContributions }) =>
          getEESG({
            survey,
            dataSources: dataSourcesState,
            questionData,
            scopeGroupHistoricalData: getScopeGroupData(
              getEESGEconomicData({ reportData: questionData, survey }),
              getScopeModules({ scope: EESG_SCOPE, metricGroups: [] }),
              blueprintContributions
            ),
            options: { displayUserInput: true },
            visibility: getDocVisibility(downloadSettings.visibility),
          })
        )
        .then(() => {
          setDownloading(false);
          setData(initialDataSourcesState);
        })
        .catch((e) => loggerMessage(e.message, { surveyId }));
    }
  }, [
    downloadSettings.visibility,
    dataSourcesState,
    surveyId,
    isLoaded,
    downloading,
    questionData,
  ]);

  const downloadModalProps: DownloadSettingPropType = {
    title: 'Sustainability report',
    isOpen: openSettings,
    code: 'eesg_2024',
    initiativeId,
    handleClose: handleCloseModal,
    onDownload: (type: DownloadType) =>
      downloadReportHandler({ type, code: '', downloadSettings, ...downloadHandlers, downloadDocx }),
    handleChange: (event) => handleSettingsChange(event, setDownloadSettings, downloadSettings),
    downloadSettings,
    totals,
  };

  return (
    <div className='eesg-report-generator mt-5'>
      {downloading ? <Loader /> : <></>}
      {questionData && !isLoaded ? <GenerateCharts updateData={updateData} questionData={questionData} /> : <></>}
      <DownloadButton
        testId={'download-report-btn-eesg'}
        className='doc-btn'
        color='secondary'
        outline={true}
        onClick={handleOpenSettings}
      >
        Download report <i className='fas fa-file-download ml-2' />
      </DownloadButton>
      <DownloadSettingModal {...downloadModalProps} />
    </div>
  );
};
