import { useContext, useState } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { QuestionManagementContext } from '../../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';
import { MetricOverrideProps } from '../types';
import { MultipleMetricOverride } from './MultipleMetricOverride';
import { SingleMetricOverride } from './SingleMetricOverride';
import { UtrvConfigCode, UtrvConfigType, UtrvConfigValue } from './contants';
import { hasDataChanged } from './utils';
import { Loader } from '@g17eco/atoms/loader';

interface Props extends Pick<MetricOverrideProps, 'handleCloseModal'> {
  isLoading: boolean;
  initialUtrvConfig: UtrvConfigType;
  isMultipleUpdate: boolean;
  handleUpdate: (utrvConfig: Partial<UtrvConfigType>) => void;
}

export const MetricOverrideForm = (props: Props) => {
  const { isLoading, initialUtrvConfig, isMultipleUpdate, handleUpdate, handleCloseModal } = props;
  const [utrvConfig, setUtrvConfig] = useState(initialUtrvConfig);

  const onChange = ({ code, value }: { code: UtrvConfigCode; value: UtrvConfigValue }) => {
    setUtrvConfig((prev) => ({ ...prev, [code]: value }));
  };

  const { utrvConfigCode } = useContext(QuestionManagementContext);
  const hasAnythingChanged = hasDataChanged(initialUtrvConfig, utrvConfig);
  const isAllowedToUpdate = hasAnythingChanged || isMultipleUpdate;

  const onClickUpdate = () => {
    if (!isAllowedToUpdate) {
      return;
    }
    // update one specific property for multiple questions, ex: { evidenceRequired: 'optional' }
    if (utrvConfigCode) {
      const selectedUtrvConfig = { [utrvConfigCode]: utrvConfig[utrvConfigCode] };
      return handleUpdate(selectedUtrvConfig);
    }
    // update whole utrv config for single question
    // ex: { noteRequired: 'default', evidenceRequired: 'optional', verificationRequired: 'required' }
    return handleUpdate(utrvConfig);
  };

  return (
    <>
      <ModalBody>
        {isLoading ? <Loader /> : null}
        {isMultipleUpdate ? (
          <MultipleMetricOverride
            hasAnythingChanged={hasAnythingChanged}
            utrvConfig={utrvConfig}
            handleChange={onChange}
          />
        ) : (
          <SingleMetricOverride utrvConfig={utrvConfig} handleChange={onChange} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' disabled={isLoading || !isAllowedToUpdate} onClick={onClickUpdate}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
