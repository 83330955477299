import { Loader } from './Loader';

interface Props {
  className?: string;
}

export const SectionLoader = ({ className = 'my-5' }: Props) => {
  return (
    <div className={className}>
      <Loader relative size='sm' />
    </div>
  );
};
