import { useContext, useMemo } from 'react';
import { useAppSelector } from '../../reducers';
import { CustomMetricRouteParams } from '@features/custom-metrics';
import { isStaff } from '../../selectors/user';
import G17Client from '../../services/G17Client';
import { AccessType, CustomMetricsUsage, MetricGroup } from '../../types/metricGroup';
import { DashboardSection } from '../dashboard';
import AddCompanyForm from './AddCompanyForm';
import CompanyAssignmentsTable from './CompanyAssignmentsTable';
import { CustomMetricsViewMode } from './constants';
import { CustomMetricContext } from './CustomMetricContainer';
import MetricGroupForm from './MetricGroupForm';
import { SURVEY } from '@constants/terminology';
import { DashboardDivider } from '@g17eco/atoms/divider';

interface MetricGroupFormContainerProps {
  isAssigned: boolean;
  customMetricsUsage: CustomMetricsUsage;
  handleReload: () => Promise<void>;
  currentMetricGroup?: MetricGroup;
  handleChangeMetricGroup: (params: CustomMetricRouteParams) => void;
}

export const MetricGroupFormContainer = (props: MetricGroupFormContainerProps) => {
  const { isAssigned, customMetricsUsage, handleReload, currentMetricGroup, handleChangeMetricGroup } = props;
  const { metricGroups, isPortfolioTracker, groupId, initiativeId } = useContext(CustomMetricContext);
  const portfolio = useAppSelector((state) => state.portfolio.data);
  const isUserStaff = useAppSelector(isStaff);
  const isNewGroupMode = groupId === CustomMetricsViewMode.Create;

  const canEdit = useMemo(() => {
    if (!currentMetricGroup) {
      return false;
    }
    if (isPortfolioTracker) {
      return isUserStaff;
    }
    return currentMetricGroup.initiativeId === initiativeId;
  }, [currentMetricGroup, isPortfolioTracker, isUserStaff, initiativeId]);

  const handleDownloadAggregatedData = () => {
    if (groupId && !isNewGroupMode) {
      const url = G17Client.getDownloadUrl(`portfolios/${initiativeId}/metrics/${groupId}/download`);
      window.open(url, '_blank', '');
    }
  };

  const isInherited = currentMetricGroup?.accessType === AccessType.Inherited;

  return (
    <>
      <MetricGroupForm
        readOnly={isAssigned || isInherited}
        canEdit={canEdit}
        allowCancel={metricGroups.length > 0}
        metricGroup={currentMetricGroup}
        customMetricsUsage={customMetricsUsage}
        handleReload={handleReload}
        handleChangeMetricGroup={handleChangeMetricGroup}
      />

      {isPortfolioTracker && currentMetricGroup?._id ? (
        <DashboardSection
          title='Company assignment'
          icon='fa-building'
          buttons={[
            {
              icon: 'fa-file-csv',
              tooltip: 'Download aggregated metric group data',
              onClick: () => handleDownloadAggregatedData(),
            },
          ]}
        >
          <p>
            Assignments allows you to invite companies in your portfolios to your custom metric group. Companies will be
            able to add these to their {SURVEY.SINGULAR} scope. Reporting can be accessed from this page.
          </p>
          {portfolio && currentMetricGroup?.share ? (
            <div className='mb-3'>
              <CompanyAssignmentsTable
                isReadOnly={!canEdit}
                metricGroup={currentMetricGroup}
                portfolio={portfolio}
                handleReload={handleReload}
              />
              <DashboardDivider />
            </div>
          ) : null}

          {canEdit && portfolio ? (
            <div className='ml-4'>
              <AddCompanyForm metricGroup={currentMetricGroup} handleReload={handleReload} portfolio={portfolio} />
            </div>
          ) : null}
        </DashboardSection>
      ) : null}
    </>
  );
};
