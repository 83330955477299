import { SurveyStats, useGetStatsQuery } from '@api/initiative-stats';
import { DATE, formatDateNonUTC, getMonth, getUTCDateRange, getYear } from '@utils/date';
import { DateRangeType } from '@g17eco/types/common';
import { DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { SurveyStatsFilters } from '../filters/SurveyStatsFilters';
import { Loader } from '@g17eco/atoms/loader';
import { useMemo, useState } from 'react';
import { SurveyStatsFilterProps, SurveyStatsStatus } from '../types';
import { getPeriodName } from '@utils/universalTracker';
import { naturalSort } from '@utils/index';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CompleteButton } from '@components/survey/button/CompleteButton';
import { BulkSurveysMessageModal, SurveyMessageModal } from '@components/message-modal/MessageModal';
import { Action, SurveysEditing } from '../surveys-editing/SurveysEditing';
import { generateSurveysFlexSearchMap } from '../utils';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import './SurveyStatsTable.scss';
import { SURVEY } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';
import { Table, ColumnDef } from '@g17eco/molecules/table';

interface Props {
  initiativeId: string;
  dateRange: DateRangeType;
  url: string;
  isUserAdmin: boolean;
  handleReload: () => Promise<void>;
}

const defaultSurveyFilters: SurveyStatsFilterProps = {
  status: SurveyStatsStatus.Pending,
};

const commonCellStyles = {
  headerProps: {
    style: {
      maxWidth: 60,
    },
  },
  cellProps: {
    className: 'status-cell text-center',
    style: {
      maxWidth: 60,
    },
  },
};

export const SurveyStatsTable = (props: Props) => {
  const { initiativeId, dateRange, url, isUserAdmin, handleReload } = props;
  const [isEditingSurveys, setIsEditingSurveys] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<string | undefined>(undefined);
  const [showBulkSurveysMessage, setShowBulkSurveysMessage] = useState<boolean>(false);
  const [filter, setFilter] = useState(defaultSurveyFilters);
  const {
    isLoading,
    isFetching,
    isSuccess,
    data = [],
  } = useGetStatsQuery({
    initiativeId,
    ...getUTCDateRange(dateRange),
  });
  const history = useHistory();

  const isCompleteFilter = filter.status === SurveyStatsStatus.Completed;

  const columns: ColumnDef<SurveyStats>[] = [
    {
      header: 'Subsidiary name',
      meta: {
        cellProps: {
          style: {
            maxWidth: 180,
          },
        },
      },
      cell: ({ row }) => {
        return (
          <Link to={`${url}/overview/${row.original._id}`}>
            <span className={'text-truncate'} style={{ display: 'block' }}>
              {row.original.initiativeName ?? '-'}
            </span>
          </Link>
        );
      },
    },
    {
      header: 'Month',
      accessorFn: (row) => formatDateNonUTC(row.effectiveDate, DATE.MONTH),
      sortingFn: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate),
    },
    {
      header: 'Year',
      accessorFn: (row) => formatDateNonUTC(row.effectiveDate, DATE.YEAR_ONLY),
      sortingFn: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate),
    },
    {
      header: 'Duration',
      accessorFn: (row) => getPeriodName(row.period, false),
    },
    {
      header: 'Updated',
      accessorFn: (row) => formatDateNonUTC(row.lastUpdated, DATE.HUMANIZE),
      sortingFn: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
    },
    {
      accessorKey: 'status.created',
      header: () => <i className='fa fa-user-clock text-secondary' />,
      meta: commonCellStyles,
    },
    {
      accessorKey: 'status.updated',
      header: () => <i className='fa fa-user-edit text-primary' />,
      meta: commonCellStyles,
    },
    {
      accessorKey: 'status.rejected',
      header: () => <i className='fa fa-user-times text-danger' />,
      meta: commonCellStyles,
    },
    {
      accessorKey: 'status.verified',
      header: () => <i className='fa fa-user-check text-success' />,
      meta: commonCellStyles,
    },
    {
      id: 'mark',
      header: () =>
        isUserAdmin ? (
          <Button onClick={() => setIsEditingSurveys(true)} color={'link'} className='btn-xs'>
            Bulk {isCompleteFilter ? 'incomplete' : 'complete'}
          </Button>
        ) : null,
      meta: {
        cellProps: {
          style: {
            width: 100,
          },
        },
      },
      cell: ({ row }) => {
        const survey = row.original;
        return (
          <CompleteButton
            color='link'
            classes={{ btn: 'btn-xs text-muted mx-0 text-left complete-btn' }}
            isUserAdmin={isUserAdmin}
            survey={survey}
            handleReload={handleReload}
          />
        );
      },
    },
    {
      id: 'message',
      header: () => (
        <Button size='sm' color={'link'} className={'btn-xs'} onClick={() => setShowBulkSurveysMessage(true)}>
          Bulk Message
        </Button>
      ),
      meta: {
        cellProps: {
          style: {
            width: 100,
          },
        },
      },
      cell: ({ row }) => (
        <Button
          size={'sm'}
          color={'link'}
          className={'btn-xs text-muted '}
          onClick={() => setShowMessageModal(row.original._id)}
        >
          Message
        </Button>
      ),
    },
  ];

  const surveyStats: SurveyStats[] = useMemo(() => {
    if (!isSuccess) {
      return [];
    }
    return data.reduce<SurveyStats[]>((acc, current) => {
      acc.push(...current.surveys);
      return acc;
    }, []);
  }, [data, isSuccess]);

  const filteredRows: SurveyStats[] = useMemo(() => {
    let rows: SurveyStats[] = surveyStats;
    const { searchText, byUserId, byInitiativeId, byYear, byMonth } = filter;
    if (searchText) {
      const searchIndex = generateSurveysFlexSearchMap(rows);
      const result = searchIndex.search(searchText);
      const matchedIds = new Set(result.map((item) => item.result).flat());
      rows = rows.filter((survey) => matchedIds.has(survey._id));
    }

    if (byInitiativeId) {
      rows = rows.filter((s) => s.initiativeId === byInitiativeId);
    }

    if (byUserId) {
      rows = rows.filter((s) => s.creator?._id === byUserId);
    }

    if (byYear) {
      rows = rows.filter((s) => getYear(s.effectiveDate) === Number(byYear));
    }

    if (byMonth) {
      rows = rows.filter((s) => getMonth(s.effectiveDate) === Number(byMonth));
    }

    return rows.filter((s) => (isCompleteFilter ? s.completedDate : !s.completedDate));
  }, [filter, surveyStats, isCompleteFilter]);

  return (
    <>
      <DashboardSectionTitle
        title={SURVEY.CAPITALIZED_PLURAL}
        buttons={[
          <Button
            key='bulk-import-button'
            className='py-1 mr-3'
            onClick={() => history.push(generateUrl(ROUTES.BULK_IMPORTING, { initiativeId }))}
          >
            <span className='lh-base'>
              <i className='fa-light fa-file-import mr-2 fs-5' />
              Bulk import data
            </span>
          </Button>,
          <Button
            key='survey-templates-button'
            className='py-1'
            onClick={() => history.push(generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId }))}
          >
            <span className='lh-base'>
              <i className='fa-light fa-layer-group mr-2 fs-5' />
              {SURVEY.CAPITALIZED_SINGULAR} templates
            </span>
          </Button>,
        ]}
      />
      <DashboardSection>
        <SurveyStatsFilters isCompleteFilter={isCompleteFilter} setFilter={setFilter} surveyStats={surveyStats} />
        {isLoading || isFetching ? (
          <Loader relative />
        ) : (
          <Table
            pageSize={10}
            columns={columns}
            data={filteredRows}
            noData={
              <BasicAlert type='secondary'>
                There are no {isCompleteFilter ? 'Completed' : 'In Progress'} {SURVEY.PLURAL}.
              </BasicAlert>
            }
          />
        )}
      </DashboardSection>
      {showMessageModal ? (
        <SurveyMessageModal surveyId={showMessageModal} toggle={() => setShowMessageModal('')} />
      ) : null}
      {isEditingSurveys ? (
        <SurveysEditing
          surveys={filteredRows}
          action={isCompleteFilter ? Action.Incomplete : Action.Complete}
          handleClose={() => setIsEditingSurveys(false)}
          handleReload={handleReload}
        />
      ) : null}
      {showBulkSurveysMessage ? (
        <BulkSurveysMessageModal surveys={filteredRows} toggle={() => setShowBulkSurveysMessage(false)} />
      ) : null}
    </>
  );
};
