import { useParams } from 'react-router-dom';
import { useGetTemplatesQuery } from '../../api/survey-templates';
import { ROUTES } from '../../constants/routes';
import { AdminBreadcrumbs } from '../../routes/admin-dashboard/AdminBreadcrumbs';
import { generateUrl } from '../../routes/util';
import Dashboard, { DashboardRow, DashboardSection } from '../dashboard';
import { Loader } from '@g17eco/atoms/loader';
import { TemplateDashboardHeader } from './partials/TemplateDashboardHeader';
import { TemplateTable } from './partials/TemplateTable';
import { RouteParams } from './types';
import { TemplateCreateDropdown } from './partials/TemplateCreateDropdown';
import { SURVEY } from '@constants/terminology';

export const TemplateDashboard = () => {
  const { initiativeId } = useParams<RouteParams>();
  const query = useGetTemplatesQuery(initiativeId);

  return (
    <Dashboard>
      <DashboardRow>
        <AdminBreadcrumbs
          breadcrumbs={[
            {
              label: 'Admin Dashboard',
              url: generateUrl(ROUTES.ADMIN_DASHBOARD, { initiativeId }),
            },
            { label: `${SURVEY.CAPITALIZED_SINGULAR} Templates` },
          ]}
          initiativeId={initiativeId}
        />
      </DashboardRow>
      {query.isFetching || query.isLoading ? (
        <div style={{ minHeight: '150px' }}>
          <Loader />
        </div>
      ) : (
        <DashboardSection header={<TemplateDashboardHeader buttons={[<TemplateCreateDropdown key='template-create' />]} />}>
          <TemplateTable />
        </DashboardSection>
      )}
    </Dashboard>
  );
};
