import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useGetTemplateByIdQuery } from '../../api/survey-templates';
import { useGetHistoryQuery } from '../../api/survey-templates-history';
import { useAppSelector } from '../../reducers';
import NotAuthorised from '../../routes/not-authorised';
import { NotFound } from '../../routes/not-found/NotFound';
import { getCurrentUser } from '../../selectors/user';
import { SurveyPermissions } from '../../services/permissions/SurveyPermissions';
import Dashboard, { DashboardSection } from '../dashboard';
import { Loader } from '@g17eco/atoms/loader';
import { DashboardDivider } from '@g17eco/atoms/divider';
import { TemplateDashboardHeader } from '../survey-templates/partials/TemplateDashboardHeader';
import { useGetSelectedHistory } from './hooks/useGetSelectedHistory';
import { TemplateSettingsHeader } from '../survey-templates/partials/TemplateSettingsHeader';
import { SubsidiarySelectionTable } from '../downloads/partials/SubsidiarySelectionTable';
import { SURVEY } from '@constants/terminology';

export const TemplateHistoryAggregatedConfiguration = () => {
  const { templateId = '' } = useParams<{ templateId: string }>();
  const historyQuery = useGetHistoryQuery(templateId, { skip: !templateId });
  const templateQuery = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const currentUser = useAppSelector(getCurrentUser);
  const selectedHistory = useGetSelectedHistory(historyQuery.data);

  const canManage = useMemo(() => {
    if (!currentUser || !selectedHistory) {
      return false;
    }
    return SurveyPermissions.canManage({ initiativeId: selectedHistory.initiativeId }, currentUser);
  }, [currentUser, selectedHistory]);

  if (historyQuery.isFetching || templateQuery.isFetching) {
    return <Loader />;
  }

  if (!selectedHistory || !templateQuery.data) {
    return <NotFound />;
  }

  if (!canManage) {
    return <NotAuthorised />;
  }

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      <DashboardSection
        header={<TemplateDashboardHeader title={`Combined ${SURVEY.SINGULAR} template: ${templateQuery.data.name}`} />}
      >
        <Form className='survey-config-form'>
          <div className='mt-4 mb-6 position-relative'>
            <h5>{SURVEY.CAPITALIZED_SINGULAR} name</h5>
            <Input placeholder={selectedHistory.snapshot.name} disabled />
          </div>
          <DashboardDivider />
          <div className='mt-4'>
            <h5>Include active subsidiaries data</h5>
            <FormGroup check>
              <Input type={'checkbox'} checked={Boolean(selectedHistory.snapshot.hasCurrentInitiatives)} disabled />
              <Label check>Include current subsidiary data in generated combined {SURVEY.PLURAL}</Label>
            </FormGroup>
          </div>
          <div className='mt-4'>
            <h5>Create combined {SURVEY.SINGULAR} at these subsidiaries</h5>
            <SubsidiarySelectionTable
              isDisabled
              initiativeId={selectedHistory.initiativeId}
              selectedIds={selectedHistory.snapshot.reportingLevels}
            />
          </div>
        </Form>
      </DashboardSection>
    </Dashboard>
  );
};
