import { g17ecoApi, transformResponse } from '@api/g17ecoApi';
import { MappingListResponse } from '@g17eco/types/utr-external-mapping';


export const initiativeExternalMapping = g17ecoApi
  .enhanceEndpoints({ addTagTypes: ['initiative-external-mapping'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      listExternalMapping: builder.query<MappingListResponse, { initiativeId: string, type: string }>({
        transformResponse,
        query: ({ initiativeId, type }) => ({
          url: `/initiatives/${initiativeId}/external-mapping/${type}`,
          method: 'get',
        }),
        providesTags: ['initiative-external-mapping']
      }),
    }),
  });

export const { useListExternalMappingQuery } = initiativeExternalMapping;

