import { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Button, Input } from 'reactstrap';
import { useToggle } from '../../hooks/useToggle';
import { DATE, formatDate } from '../../utils/date';
import './styles.scss';

export type DatePickerProps = {
  date: Date | undefined;
  callbackChange: (date: Date | undefined) => void;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
};

export const DatePicker = (props: DatePickerProps) => {
  const { date, callbackChange, disabled, className, minDate } = props;
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showCalendar, toggleCalendar, setShowCalendar] = useToggle();

  useEffect(() => {
    if (date) {
      setSelectedDate(new Date(date));
    }
  }, [date]);

  const handleClose = () => {
    if (date) {
      setSelectedDate(new Date(date));
    }
    setShowCalendar(false);
  };

  const handleToggleCalendar = () => {
    if (disabled) {
      return;
    }
    toggleCalendar();
  };

  const handleChange = () => {
    callbackChange(selectedDate);
    setShowCalendar(false);
  };

  return (
    <div className='date-picker position-relative'>
      <div className='position-relative' onClick={() => handleToggleCalendar()}>
        <Input
          readOnly
          value={date ? formatDate(date, DATE.UK) : ''}
          placeholder='dd / mm / yyyy'
          disabled={disabled}
        />
        <i className='input-icon fa-light fa-calendar'></i>
      </div>
      {showCalendar ? (
        <div className={`date-picker-wrapper d-flex flex-column p-2 ${className}`}>
          <Calendar onChange={(item) => setSelectedDate(item)} date={selectedDate} minDate={minDate} />
          <div className='text-end pr-2 pt-2'>
            <Button size='sm' color='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button size='sm' onClick={handleChange}>
              Apply
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
