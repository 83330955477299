import { CollapsePanel } from './CollapsePanel';
import { CollapseButton } from './CollapseButton';
import { CollapseContent } from './CollapseContent';
import { useToggle } from '@hooks/useToggle';
import classNames from 'classnames';
import './styles.scss';

interface Props {
  initialValue?: boolean;
  trigger: JSX.Element;
  children: React.ReactNode;
}

export const CollapseGroup = (props: Props) => {
  const { initialValue = true, trigger, children } = props;
  const [collapsed, toggleCollapse] = useToggle(initialValue);

  return (
    <CollapsePanel
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      className={classNames('collapse-group mt-3', { 'background-ThemeBgMedium': !collapsed })}
    >
      <CollapseButton classes={{ wrapper: 'ml-0' }}>{trigger}</CollapseButton>
      <CollapseContent className='px-3 py-2'>{children}</CollapseContent>
    </CollapsePanel>
  );
};
