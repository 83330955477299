import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  useGetCreateAggregatedSurveyStatusQuery,
  useCreateAutoAggregatedSurveyMutation,
} from '@api/auto-aggregated-survey';
import { ROUTES } from '@constants/routes';
import { generateUrl } from '@routes/util';
import { reloadSurveyList } from '@g17eco/slices/initiativeSurveyListSlice';
import { DashboardSection } from '@components/dashboard';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { Loader } from '@g17eco/atoms/loader';
import { ExtraFeature, FeatureStability } from '@g17eco/molecules/feature-stability';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { QUESTION, SURVEY } from '@constants/terminology';

const CREATE_AGGREGATED_SURVEY_MESSAGE = `Auto-aggregated ${SURVEY.SINGULAR} is generating...`;

export const AutoAggregatedSurveyCreate = ({ initiativeId }: { initiativeId: string | undefined }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isStaffUser = useAppSelector(isStaff);
  const { addSiteError } = useSiteAlert();

  const [createAutoAggregatedSurvey] = useCreateAutoAggregatedSurveyMutation();
  const { data, isLoading, isError } = useGetCreateAggregatedSurveyStatusQuery(
    initiativeId && isStaffUser ? initiativeId : skipToken
  );
  const [message, setMessage] = useState<string>('');

  if (isError) {
    return null;
  }

  const autoAggregatedSurveyId = data && 'surveyId' in data ? data.surveyId : undefined;

  const resetMessage = () => {
    setMessage('');
  };

  const handleClick = async () => {
    if (!initiativeId) {
      return;
    }

    if (autoAggregatedSurveyId) {
      return goToAggregatedSurveyOverview(autoAggregatedSurveyId);
    }

    createAutoAggregatedSurvey(initiativeId)
      .unwrap()
      .then((result) => {
        if ('surveyId' in result) {
          dispatch(reloadSurveyList());
          return goToAggregatedSurveyOverview(result.surveyId);
        }

        if ('jobId' in result) {
          return setMessage(CREATE_AGGREGATED_SURVEY_MESSAGE);
        }
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const goToAggregatedSurveyOverview = (surveyId: string) => {
    const url = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
      initiativeId,
      surveyId,
      page: 'overview',
    });
    return history.push(url);
  };

  const isEnabled =
    data && 'canCreateAggregatedSurvey' in data ? data.canCreateAggregatedSurvey : Boolean(autoAggregatedSurveyId);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <DashboardSection
        title={
          <span>
            Aggregated {SURVEY.SINGULAR} <FeatureStability feature={ExtraFeature.AutoAggregatedSurvey} />
          </span>
        }
      >
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            This {SURVEY.SINGULAR} will automatically answer {QUESTION.PLURAL} using the data it has aggregated from its
            children.
          </div>
          <SimpleTooltip text={!isEnabled ? `No children ${SURVEY.PLURAL} available` : ''}>
            <Button color='primary' onClick={handleClick} disabled={isLoading || !isEnabled}>
              View aggregated {SURVEY.SINGULAR}
            </Button>
          </SimpleTooltip>
        </div>
        <Modal isOpen={!!message} toggle={resetMessage} backdrop='static'>
          <ModalHeader toggle={resetMessage}>Auto-aggregated {SURVEY.SINGULAR}</ModalHeader>
          <ModalBody>{message}</ModalBody>
        </Modal>
      </DashboardSection>
    </>
  );
};
