import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { useGetHistoryByIdQuery } from '../../api/survey-templates-history';
import { TemplateHistory } from '../survey-templates/types';
import { BulkSurveysProgressBar } from './BulkSurveysProgressBar';
import { BulkSurveysResultTable } from './BulkSurveysResultTable';
import { JobStatus } from '../../types/background-jobs';
import { ProgressLoader } from '@g17eco/atoms/loader';
import { SURVEY } from '@constants/terminology';

interface BulkSurveysModalParams {
  templateId: string;
  historyId: string | undefined;
  pollingInterval?: number
  isOpen: boolean;
  toggle: () => void;
}

export const BulkSurveysModal = (props: BulkSurveysModalParams) => {

  const { templateId, historyId = '', isOpen, toggle, pollingInterval: interval = 5000 } = props;

  const [pollingInterval, setPollingInterval] = useState(interval);

  const { data: currentHistory } = useGetHistoryByIdQuery({ templateId, historyId }, {
    skip: !historyId,
    pollingInterval: pollingInterval,
  });

  useEffect(() => {
    if (currentHistory?.completedDate) {
      setPollingInterval(currentHistory?.completedDate ? 0 : interval)
    }
  }, [currentHistory?.completedDate, interval])


  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
      <BulkSurveysCompleteModal templateHistory={currentHistory} handleClose={toggle} />
    </Modal>
  );
};

interface ProcessingModalProps {
  templateHistory: TemplateHistory | undefined;
  handleClose: () => void;
}

const ProcessingModal = ({ templateHistory, handleClose}: ProcessingModalProps) => {
  return (
    <>
      <ModalHeader toggle={handleClose}>
        <Spinner
          className={classNames({
            'position-relative': true,
            'align-self-center': true,
            'mr-2': true,
          })}
          size='sm'
        />
        Your {SURVEY.PLURAL} are being created
      </ModalHeader>
      <ModalBody>
        {templateHistory ? <ProgressLoader className='w-100 pb-4' /> : null}
        <p>
          Your request is currently being processed. Please be patient as depending on the number of {SURVEY.PLURAL}{' '}
          being created, this process could take upwards of 10 minutes.
        </p>
        <p className='mb-0'>We will send you a notification once your request has been completed.</p>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='primary' onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};


const BulkSurveysCompleteModal = (props: ProcessingModalProps) => {
  const { templateHistory, handleClose } = props;

  if (!templateHistory) {
    return <ProcessingModal {...props}  />
  }

  const levelCount = templateHistory.snapshot.reportingLevels?.length;
  const results = templateHistory.results;
  const isCompleted = templateHistory.completedDate || (levelCount === undefined && results.length > 0)

  if (!isCompleted) {
    return <ProcessingModal {...props}  />
  }

  const erroredSurveys = results.filter((result) => result.status === JobStatus.Error);

  return (
    <>
      <ModalHeader toggle={handleClose}>
        <i className='fa fa-check-circle text-ThemeHeadingMedium mr-2' />
        Your {SURVEY.PLURAL} have been created
      </ModalHeader>
      <ModalBody>
        <BulkSurveysProgressBar templateHistory={templateHistory} />
        {erroredSurveys.length > 0 ? (
          <>
            <p>
              Request completed. Please note an error has occurred during the creation process of{' '}
              {results.length} {SURVEY.SINGULAR}(s). Details regarding these errors can be found below:
            </p>
            <BulkSurveysResultTable erroredSurveys={erroredSurveys} />
          </>
        ) : (
          <p>Request completed successfully, no errors occurred during the creation process.</p>
        )}
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='primary' onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
