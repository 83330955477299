import { useCallback, useEffect, useState } from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { delegateQuestions, getDelegatedAssurers } from '../../../actions/assurance';
import { Action } from '../../../constants/action';
import { AssurancePortfolioStakeholder, OrganizationAssurancePortfolio } from '../../../types/assurance';
import { getFullName } from '../../../utils/user';
import { Loader } from '@g17eco/atoms/loader';
import { Suggestion } from '../../search/SearchComponent';
import UserSearch from '../../search/UserSearch';
import { QUESTION } from '@constants/terminology';
import { BasicAlert } from '@g17eco/molecules/alert';
import { Table } from '@g17eco/molecules/table';

interface AssignButtonProps {
  assurancePortfolio: OrganizationAssurancePortfolio;
  utrvAssuranceIds: string[];
}

interface SubmitData extends Partial<Suggestion> {
  submitting: boolean;
}

type AssurerChangeFn = (user: AssurancePortfolioStakeholder) => void;

const initialSubmitData: SubmitData = { submitting: false };

function getUserRow(user: AssurancePortfolioStakeholder, handleRemove: AssurerChangeFn) {
  return (
    <div className='user-row__wrapper'>
      <Button color='link' className='mx-0 p-0' onClick={() => handleRemove(user)}>
        <i className='fas fa-times text-ThemeDangerMedium' />
      </Button>
      <span>{getFullName(user)}</span>
    </div>
  );
}

export const AssignButton = ({ assurancePortfolio, utrvAssuranceIds }: AssignButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [delegatedAssurers, setDelegatedAssurers] = useState<AssurancePortfolioStakeholder[]>([]);
  const [message, setMessage] = useState('');
  const [submitData, setSubmitData] = useState<SubmitData>(initialSubmitData);

  const toggleModal = () => setIsOpen((prevState) => !prevState);

  const setAssurer = (value: string, suggestion?: Suggestion) => {
    setSubmitData({
      ...submitData,
      _id: suggestion ? suggestion._id : undefined,
    });
  };

  const canSubmit = !submitData.submitting && submitData._id;

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if(utrvAssuranceIds.length === 0) {
      setDelegatedAssurers([]);
      return;
    }
    getDelegatedAssurers(assurancePortfolio._id, { utrvAssuranceIds })
      .then((assurers) => setDelegatedAssurers(assurers))
      .catch((e) => {
        setMessage(e.message);
        setDelegatedAssurers([]);
      });
  }, [assurancePortfolio._id, utrvAssuranceIds, isOpen]);

  const handleSubmit = async () => {
    setSubmitData({ ...submitData, submitting: true });
    return delegateQuestions(assurancePortfolio._id, {
      utrvAssuranceIds,
      action: Action.Add,
      userId: submitData._id,
    })
      .then((addedAssurer) => {
        setDelegatedAssurers([...delegatedAssurers, addedAssurer]);
        setSubmitData({
          ...initialSubmitData
        });
      })
      .catch((e) => {
        setMessage(e.message);
      });
  };

  const handleRemove = useCallback(async (user: AssurancePortfolioStakeholder) => {
    setSubmitData({ ...submitData, submitting: true });
    return delegateQuestions(assurancePortfolio._id, {
      utrvAssuranceIds,
      action: Action.Remove,
      userId: user._id,
    }).then((removedAssurer) => {
      setDelegatedAssurers(delegatedAssurers.filter(assurer => assurer._id !== removedAssurer._id));
      setSubmitData({
        ...initialSubmitData
      });
    })
    .catch((e) => {
      setMessage(e.message);
    });
  }, [assurancePortfolio._id, delegatedAssurers, submitData, utrvAssuranceIds]);

  return (
    <>
      <Button className='ml-2 px-3' outline onClick={toggleModal}>
        <i className='fal fa-users-medical text-ThemeIconSecondary mr-2' />
        Delegate
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' className='modal-md'>
        <ModalHeader toggle={toggleModal}>Delegate {QUESTION.PLURAL}</ModalHeader>
        <ModalBody>
          {submitData.submitting ? (
            <Loader />
          ) : (
            <div className={'add-stakeholder'}>
              <div className='add-member'>
                <BasicAlert type={'danger'}>{message}</BasicAlert>
                <FormGroup className='mb-0'>
                  <div>
                    <label>Assign a new assurer for these {QUESTION.PLURAL}:</label>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <UserSearch
                        name={'stakeholder'}
                        ignoredIds={[...delegatedAssurers.map((assurer) => assurer._id)]}
                        placeholder={'Search name/email'}
                        required={true}
                        assurancePortfolioId={assurancePortfolio._id}
                        inputClassName={'form-control'}
                        handleValueChange={setAssurer}
                      />
                    </div>
                  </div>
                </FormGroup>

                <div className='text-right mt-2'>
                  <Button disabled={!canSubmit} color='primary' onClick={handleSubmit}>
                    Delegate to assurer
                  </Button>
                </div>
              </div>
              <div>
                <div className='mt-2 pb-2 pb-lg-0 pr-lg-0'>
                  <div className='user-list'>
                    <Table
                      data={delegatedAssurers}
                      columns={[
                        {
                          header: 'Already delegated assurers',
                          id: 'name',
                          cell: ({ row }) => getUserRow(row.original, handleRemove),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
