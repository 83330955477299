import { DoubleMaterialityAssessmentData } from '@apps/materiality-tracker/api/materiality-assessment';
import { Loader } from '@g17eco/atoms/loader';
import { useMemo } from 'react';
import Chart from 'react-google-charts';
import { ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import './styles.scss';
import variables from '../../../../css/variables.module.scss';

const WIDTH = '100%';
const HEIGHT = 400;
const COLUMN_WIDTH = '93%';
const HEADERS = ['Topic', 'Financial score', { role: 'style' }, 'Impact score', { role: 'style' }];
const COLORS = {
  BORDER: variables.BorderDefault,
  BASELINE: variables.BgExtralight  ,
  FINANCIAL_COLUMN: variables.ChartDark,
  NONFINANCIAL_COLUMN: variables.ChartMedium,
};

export const AssessmentInsightsDoubleMaterialityChart = ({
  data,
  selectedTopicCode,
  columnClickHandler,
}: {
  data: DoubleMaterialityAssessmentData[];
  selectedTopicCode: string;
  columnClickHandler: (index: number) => void;
}) => {
  const chartData = useMemo(() => {
    const selectedIndex = data.findIndex((topic) => topic.code === selectedTopicCode);
    return data.map((topic, index) => [
      topic.name,
      topic.financialScore || NaN,
      index !== selectedIndex ? 'opacity: 0.32' : 'opacity: 1',
      topic.nonFinancialScore || NaN,
      index !== selectedIndex ? 'opacity: 0.32' : 'opacity: 1',
    ]);
  }, [data, selectedTopicCode]);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback({ chartWrapper }) {
        const [{ row }] = chartWrapper.getChart().getSelection();
        if (row === null) {
          return;
        }
        columnClickHandler(row);
      },
    },
  ];

  return (
    <div className='assessment__insights-chart'>
      <Chart
        chartType='ColumnChart'
        data={[HEADERS, ...chartData]}
        chartEvents={chartEvents}
        loader={<Loader />}
        options={{
          bar: {
            groupWidth: COLUMN_WIDTH,
          },
          chartArea: {
            top: 40,
            width: WIDTH,
            height: HEIGHT,
            backgroundColor: {
              stroke: COLORS.BORDER,
              strokeWidth: 1,
            },
          },
          hAxis: {
            textPosition: 'none',
          },
          vAxis: {
            gridlines: {
              count: 0,
            },
          },
          legend: { position: 'top', maxLines: 1 },
          baselineColor: COLORS.BASELINE,
          colors: [COLORS.FINANCIAL_COLUMN, COLORS.NONFINANCIAL_COLUMN],
          isStacked: true,
        }}
        width={WIDTH}
        height={HEIGHT}
      />
    </div>
  );
};
