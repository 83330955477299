import { g17ecoApi, transformResponse } from '@api/g17ecoApi';
import { CreateReportDocumentMin, ReportDocument } from '@g17eco/types/reportDocument';


interface PartnerGet {
  initiativeId: string,
  reportId: string
}

export const reportDocumentApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['report-documents', 'report-document'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      listReportDocuments: builder.query<ReportDocument[], { initiativeId: string }>({
        transformResponse,
        query: ({ initiativeId }) => ({
          url: `/initiatives/${initiativeId}/report-documents`,
          method: 'get',
        }),
        providesTags: ['report-documents']
      }),
      getReportDocument: builder.query<ReportDocument, PartnerGet>({
        transformResponse,
        query: ({ initiativeId, reportId }) => ({
          url: `/initiatives/${initiativeId}/report-documents/${reportId}`,
          method: 'get',
        }),
        providesTags: (_result, _error, arg) => [{ type: 'report-document', id: arg.reportId }],
      }),
      createReportDocument: builder.mutation<ReportDocument, CreateReportDocumentMin>({
        transformResponse,
        query: (data) => ({
          url: `/initiatives/${data.initiativeId}/report-documents`,
          method: 'post',
          data: data,
        }),
        invalidatesTags: ['report-documents'],
      }),
      updateReportDocument: builder.mutation<ReportDocument, ReportDocument>({
        transformResponse,
        query: (data) => ({
          url: `/initiatives/${data.initiativeId}/report-documents/${data._id}`,
          method: 'put',
          data: data,
        }),
        invalidatesTags: (_result, _error, agr) => {
          return ['report-documents', { type: 'report-document', id: agr._id }];
        },
      }),
    }),
  });

export const {
  useListReportDocumentsQuery,
  useGetReportDocumentQuery,
  useCreateReportDocumentMutation,
  useUpdateReportDocumentMutation,
} = reportDocumentApi;
