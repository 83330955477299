import React, { useCallback, useEffect, useState } from 'react';
import G17Client from '../../../services/G17Client';
import { getSDGImpact } from '../outputs';
import GenerateCharts from './GenerateCharts';
import { ReportData } from '../../../types/reportData';
import { GenderSplitDataSource } from './data/GenderSplit';
import { GenderSplitPcDataSource } from './data/GenderSplitPc';
import { GHGEmissionsDataSource } from './data/GHGEmissions';
import { LivingWageDataSource } from './data/LivingWage';
import { PayGapDataSource } from './data/PayGap';
import { GenderPayGapDataSource } from './data/GenderPayGap';
import { BonusesDataSource } from './data/Bonuses';
import { BoardGenderDataSource } from './data/BoardGender';
import { BoardDiversityDataSource } from './data/BoardDiversity';
import { PoliciesDataSource } from './data/Policies';
import { Loader } from '@g17eco/atoms/loader';
import { DownloadButton } from '../../button/DownloadButton';
import { getAnalytics } from '../../../services/analytics/AnalyticsService';
import { AnalyticsEvents } from '../../../services/analytics/AnalyticsEvents';
import { DownloadSettingModal, DownloadSettingPropType } from '../../downloads/DownloadSettingModal';
import { convertToUtrvStatuses, downloadReportHandler, DownloadSettingsType, DownloadXlsxOrCsv, DownloadUtrvStatusCombined } from '../../downloads/util/downloadReportHandler';
import { UtrvStatus } from '../../../constants/status';
import { DownloadMultiScope, ScopeTotals, VisibilityStatus } from '../../../types/download';
import { handleSettingsChange } from '@components/downloads/util/custom';
import { getDocVisibility } from '../sgx-metric-report/downloadScope';
import { ScopeGroups } from '../../../model/surveyData';
import { reportMultiScopeData } from '../reportApi';
import { AppConfig } from '@g17eco/types/app';

interface Props {
  initiativeId: string;
  surveyId: string;
  publicQuestionsOnly?: boolean;
  utrvStatuses?: UtrvStatus[];
  downloadXlsxOrCsv?: DownloadXlsxOrCsv;
  totals?: ScopeTotals;
  defaultDownloadSettings?: Partial<DownloadSettingsType>;
  defaultDownloadOptions?: AppConfig['settings']['defaultDownloadOptions'];
}

export interface DataSource {
  loaded: boolean;
  width?: number;
  height?: number;
  chart?: string;
}

export interface DataSources {
  genderSplit: GenderSplitDataSource;
  genderSplitPc: GenderSplitPcDataSource;
  livingWage: LivingWageDataSource;
  ghgEmissions: GHGEmissionsDataSource;
  payGap: PayGapDataSource;
  genderPayGap: GenderPayGapDataSource;
  bonuses: BonusesDataSource;
  boardGender: BoardGenderDataSource;
  boardDiversity: BoardDiversityDataSource;
  policies: PoliciesDataSource;
}
const initialDataState = {
  loaded: false
};

const initialDataSourcesState: DataSources = {
  genderSplit: initialDataState,
  genderSplitPc: initialDataState,
  livingWage: initialDataState,
  ghgEmissions: initialDataState,
  payGap: initialDataState,
  genderPayGap: initialDataState,
  bonuses: initialDataState,
  boardGender: initialDataState,
  boardDiversity: initialDataState,
  policies: initialDataState,
};

const type = 'ctl';

export const SDGImpactDownloadButton = ({
  surveyId,
  initiativeId,
  utrvStatuses,
  publicQuestionsOnly,
  totals,
  defaultDownloadSettings,
  defaultDownloadOptions,
  ...downloadHandlers
}: Props) => {
  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(true);
  const [questionData, setQuestionData] = useState<ReportData[]>()
  const [dataSourcesState, setData] = useState(initialDataSourcesState);
  const [isLoaded, setLoaded] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsType>({
    selectedScopes: [], // selection not enabled since is fixed ctl
    visibility: VisibilityStatus.Include,
    status: DownloadUtrvStatusCombined.All,
    ...defaultDownloadSettings
  })

  const handleCloseModal = () => setOpenSettings(false);
  const handleOpenSettings = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.blur();
    }
    setOpenSettings(true);
  };

  const analytics = getAnalytics();

  const downloadDocx = async () => {
    setDownloaded(false);
    setDownloading(true);
    try {
      const appliedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
      const statuses = convertToUtrvStatuses(appliedStatus);
      const downloadScope: DownloadMultiScope = {
        scope: {
          [ScopeGroups.Frameworks]: [type],
          [ScopeGroups.Standards]: [],
          [ScopeGroups.Custom]: [],
          [ScopeGroups.Sdg]: [],
        },
        visibility: getDocVisibility(downloadSettings.visibility),
        targets: true, // Include targets
        ...statuses,
        displayMetricOverrides: downloadSettings.displayMetricOverrides,
      };
      const data = await reportMultiScopeData(
        surveyId,
        downloadScope
      );
      setQuestionData(data);
    } catch (error) {
      console.log(error.message);
    }

    return analytics.track(AnalyticsEvents.SurveyDataDownloaded, {
      initiativeId,
      surveyId: surveyId,
      scopeValue: type,
      source: 'downloads_page',
      type: 'docx',
    })
  };

  const updateData = useCallback((key: string, data: DataSource) => {
    setData((dataSources) => ({
      ...dataSources,
      [key]: data
    }));
  }, [setData])

  useEffect(() => {
    const allLoaded = Object.keys(dataSourcesState).every(k => dataSourcesState[k as keyof DataSources].loaded);
    setLoaded(allLoaded);
  }, [dataSourcesState]);

  useEffect(() => {
    if (isLoaded && downloading) {
      setDownloading(false);
      G17Client.getSurveyListItem(surveyId)
        .then((survey) =>
          getSDGImpact(survey, dataSourcesState, {
            publicQuestionsOnly, // TODO: always undefined as we don't pass in?
            utrvStatuses, // TODO: always undefined as we don't pass in?
            displayUserInput: true,
            displayMetricOverrides: downloadSettings.displayMetricOverrides,
          })
        )
        .then(() => {
          setDownloaded(true);
          setData(initialDataSourcesState);
          setQuestionData(undefined);
          setLoaded(false);
        })
        .catch((e) => console.log(e.message));
    }
  }, [dataSourcesState, surveyId, isLoaded, downloading, utrvStatuses, publicQuestionsOnly, downloadSettings])

  const downloadModalProps: DownloadSettingPropType = {
    title: 'SDG Impact report',
    isOpen: openSettings,
    initiativeId,
    handleClose: handleCloseModal,
    onDownload: (type, config) => {
      return downloadReportHandler({
        type,
        code: 'ctl',
        downloadSettings,
        ...downloadHandlers,
        downloadDocx,
        config,
      });
    },
    handleChange: (event) => handleSettingsChange(event, setDownloadSettings, downloadSettings),
    downloadSettings,
    defaultDownloadOptions,
    totals,
  };

  return <div className='ctl-report-generator mt-5'>
    {!downloaded ? <Loader /> : <></>}
    {questionData && !isLoaded ? <GenerateCharts updateData={updateData} questionData={questionData} /> : <></>}
    <DownloadButton
      className='doc-btn'
      color='secondary'
      outline={true}
      onClick={handleOpenSettings}>
      Download report <i className='fas fa-file-download ml-2' />
    </DownloadButton>
    <DownloadSettingModal {...downloadModalProps}/>
  </div>
}
