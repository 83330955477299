import { UtrValueType } from '@g17eco/types/universalTracker';
import { QUESTION } from '@constants/terminology';
import { SingleInputAnswer } from './partials/SingleInputAnswer';
import { MultiInputAnswer } from './partials/MultiInputAnswer';
import { useGetAIUtrvAssistantQuery } from '@api/ai';
import { QueryError } from '@components/query/QueryError';
import { DashboardDivider } from '@g17eco/atoms/divider';
import { SectionLoader } from '@g17eco/atoms/loader';
import { AnswerProps, PreviousUtrv } from './types';
import { getAdditionalContext, isSupportedPrediction } from './utils';
import { useConnectionContext } from '../connections/ConnectionContext';
import { TableDataInfo } from '@components/survey/question/questionInterfaces';
import { MetricBreakdown } from './partials/MetricBreakdown';

export interface AnswerDataProps extends AnswerProps {
  hasPreviousUtrvs: boolean;
  isFetching: boolean;
  predictedData?: string | number | { [key: string]: string | number | string[] };
  targetValue?: number;
  previousUtrvs: PreviousUtrv[];
  table: TableDataInfo;
}

const renderAnswerData = (props: AnswerDataProps) => {
  const { utr, predictedData } = props;

  switch (utr.valueType) {
    case UtrValueType.Text:
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      const predictedAnswer = predictedData && typeof predictedData !== 'object' ? predictedData : '';
      return <SingleInputAnswer predictedAnswer={predictedAnswer} {...props} />;
    }
    case UtrValueType.TextValueList:
    case UtrValueType.NumericValueList:
    case UtrValueType.Table: {
      const predictedAnswers = predictedData && typeof predictedData === 'object' ? predictedData : {};
      const converted = Object.entries(predictedAnswers).reduce<{ [key: string]: number | string }>(
        (acc, [key, value]) => {
          if (Array.isArray(value)) {
            acc[key] = value.join(', ');
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {}
      );

      return <MultiInputAnswer predictedAnswers={converted} {...props} />;
    }
    default: {
      return null;
    }
  }
};

export const Answer = (props: AnswerProps) => {
  const { utr, utrv } = props;
  const {
    currentInputData: { table },
  } = useConnectionContext();

  const isSupported = isSupportedPrediction(utr);
  const { data, isFetching, isError, error } = useGetAIUtrvAssistantQuery(
    { initiativeId: utrv.initiativeId, utrvId: utrv._id, ...getAdditionalContext({ utr, table }) },
    { skip: !isSupported, refetchOnMountOrArgChange: true }
  );

  const { previousUtrvs = [], targetValue, predictedAnswer } = data || {};
  const hasPreviousUtrvs = Boolean(previousUtrvs.length);

  return (
    <>
      {isError ? <QueryError error={error} type={'danger'} /> : null}
      {isFetching ? <SectionLoader /> : <MetricBreakdown {...data} />}
      <DashboardDivider className='' />
      {isSupported ? (
        renderAnswerData({
          utr,
          utrv,
          table,
          predictedData: predictedAnswer,
          hasPreviousUtrvs,
          isFetching,
          targetValue,
          previousUtrvs,
        })
      ) : (
        <p className='text-ThemeTextDark'>This {QUESTION.SINGULAR} is not yet supported</p>
      )}
    </>
  );
};
