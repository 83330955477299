import type { InputType } from 'reactstrap/types/lib/Input';
import { FormGenerator, FieldProps } from '@g17eco/molecules/form';
import { Form } from '../EditMetricModal';
import { DEFAULT_PERCENTAGE_RESTRICTIONS, UNSET_PERCENTAGE_RESTRICTIONS, hasMinAndMax } from '../utils';
import { isDefined } from '../../../utils';
import { useState } from 'react';
import { UniversalTrackerPlain, ValueValidation } from '@g17eco/types/universalTracker';

interface Props {
  formData: Form;
  setValueValidation: (value: ValueValidation) => void;
  initialData: UniversalTrackerPlain | undefined;
}

enum RangeOption {
  Yes = 'yes',
  No = 'no',
}

interface PercentageSettings {
  isRangeEnabled: RangeOption;
  min: number | undefined;
  max: number | undefined;
}

const bypassValidation = ({
  min,
  max,
  isRangeEnabled,
}: {
  min: number | undefined;
  max: number | undefined;
  isRangeEnabled: RangeOption;
}) => (!isDefined(min) && !isDefined(max)) || isRangeEnabled === RangeOption.No;

const getPercentageFieldsMap = (settings: PercentageSettings): { [k: string]: FieldProps } => {
  const { isRangeEnabled } = settings;
  return {
    isRangeEnabled: {
      code: 'isRangeEnabled',
      type: 'radio' as InputType,
      label: 'Restrict answers to a value range',
      options: [
        { value: RangeOption.Yes, label: 'Yes' },
        { value: RangeOption.No, label: 'No' },
      ],
      classes: {
        label: 'fw-bold',
        inputWrapper: 'd-flex gap-3',
      },
    },
    min: {
      code: 'min',
      type: 'number',
      required: isRangeEnabled === RangeOption.Yes,
      disabled: isRangeEnabled === RangeOption.No,
      label: 'Minimum',
      isValid: (fieldsForm) => {
        const { min, max } = fieldsForm;

        if (bypassValidation({ min: parseValue(min), max: parseValue(max), isRangeEnabled })) {
          return { message: '' };
        }

        if (!Number.isInteger(min)) {
          return { valid: false, message: 'Minimum must be an integer' };
        }

        if (min >= max) {
          return { valid: false, message: 'Minimum must be less than maximum' };
        }

        return { valid: true, message: '' };
      },
    },
    max: {
      code: 'max',
      type: 'number',
      required: isRangeEnabled === RangeOption.Yes,
      disabled: isRangeEnabled === RangeOption.No,
      label: 'Maximum',
      isValid: (fieldsForm) => {
        const { min, max } = fieldsForm;
        if (bypassValidation({ min: parseValue(min), max: parseValue(max), isRangeEnabled })) {
          return { message: '' };
        }

        if (!Number.isInteger(max)) {
          return { valid: false, message: 'Maximum must be an integer' };
        }

        if (min >= max) {
          return { valid: false, message: 'Maximum must be larger than minimum' };
        }

        return { valid: true, message: '' };
      },
    },
  };
};

const getSettings = (formData: Form): PercentageSettings => {
  const { valueValidation } = formData;

  if (hasMinAndMax(valueValidation)) {
    return {
      isRangeEnabled: RangeOption.Yes,
      min: valueValidation['min'],
      max: valueValidation['max'],
    };
  }

  if (!formData._id) {
    return {
      isRangeEnabled: RangeOption.Yes,
      ...DEFAULT_PERCENTAGE_RESTRICTIONS,
    };
  }

  return {
    isRangeEnabled: RangeOption.No,
    ...UNSET_PERCENTAGE_RESTRICTIONS,
  };
};

const parseValue = (value: string | number | undefined) =>
  value === undefined || value === '' ? undefined : parseInt(value.toString());

export const PercentageSettingsFields = (props: Props) => {
  const { formData, setValueValidation, initialData } = props;
  const [settings, setSettings] = useState(getSettings(formData));
  const fieldsMap = getPercentageFieldsMap(settings);

  const updatePercentageForm = (e: React.ChangeEvent<{ name: string; value: string | number | undefined }>) => {
    const name = e.target.name as keyof PercentageSettings;
    const value = e.target.value;
    const valueValidation = {
      min: settings.min,
      max: settings.max,
    };

    if (name === 'isRangeEnabled') {
      if (value === RangeOption.No) {
        setSettings({ isRangeEnabled: RangeOption.No, ...UNSET_PERCENTAGE_RESTRICTIONS });
        setValueValidation(UNSET_PERCENTAGE_RESTRICTIONS);
        return;
      }
      const { min, max } =
        formData._id && initialData?.valueValidation && hasMinAndMax(initialData.valueValidation)
          ? initialData.valueValidation
          : DEFAULT_PERCENTAGE_RESTRICTIONS;
      setSettings({ isRangeEnabled: RangeOption.Yes, min, max });
      setValueValidation({ min, max });
      return;
    }
    const parsedValue = parseValue(value);

    setSettings((prev) => ({ ...prev, [name]: parsedValue }));
      setValueValidation({
        ...valueValidation,
        [name]: parsedValue,
      });
      return;
  };

  return (
    <>
      <FormGenerator fields={[fieldsMap.isRangeEnabled]} form={settings} updateForm={updatePercentageForm} />
      <div className='d-flex gap-4'>
        <FormGenerator fields={[fieldsMap.min]} form={settings} updateForm={updatePercentageForm} />
        <FormGenerator fields={[fieldsMap.max]} form={settings} updateForm={updatePercentageForm} />
      </div>
    </>
  );
};
