import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '@reducers/index';
import { PortfolioDetails } from '@components/company-settings/PortfolioDetails';
import { BlockingLoader } from '@g17eco/atoms/loader';
import { currentPortfolio } from '@selectors/portfolio';

export const CompanySettingsRoute = () => {
  const { path } = useRouteMatch();
  const portfolio = useAppSelector(currentPortfolio);

  return (
    <Switch>
      <Route path={path}>{!portfolio ? <BlockingLoader /> : <PortfolioDetails portfolio={portfolio} />}</Route>
    </Switch>
  );
};
