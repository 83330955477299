import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SurveyGroupTable } from './SurveyGroupTable';
import './OutdatedSurveyAlert.scss';
import { SurveyActionData } from '../../../model/surveyData';
import { MetricActionType } from './constants';
import { Loader } from '@g17eco/atoms/loader';
import { useHandleScopeUpdateMutation } from '../../../api/surveys';
import { reloadSurvey } from '../../../actions/survey';
import { useDispatch } from 'react-redux';
import { PACK, SURVEY } from '@constants/terminology';
import { BannerAlert } from '@g17eco/molecules/alert';

interface OutdatedSurveyAlertProps {
  initiativeId: string;
  surveyData: SurveyActionData;
}

export const OutdatedSurveyAlert = ({ initiativeId, surveyData }: OutdatedSurveyAlertProps) => {
  const [isShowingBanner, setIsShowingBanner] = useState(false);
  const [isStakeholderOpen, setStakeholderOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [handleScopeUpdate] = useHandleScopeUpdateMutation();
  const dispatch = useDispatch();

  const toggle = () => setStakeholderOpen((isOpen) => !isOpen);
  const hideBanner = () => setIsShowingBanner(false);
  const { scopeUpdates = [] } = surveyData;
  const displayGroupNames = scopeUpdates.map((scopeUpdate) => scopeUpdate.name);

  useEffect(() => {
    if (scopeUpdates.length > 0) {
      setIsShowingBanner(true);
      if (!surveyData.ignoredDate) {
        toggle();
      }
    }
  }, [surveyData.ignoredDate, scopeUpdates.length]);

  const handleIgnoreChanges = async () => {
    setIsLoading(true);
    await handleScopeUpdate({ action: MetricActionType.Ignore, surveyId: surveyData._id });
    dispatch(reloadSurvey(surveyData._id));
    toggle();
    setIsLoading(false);
  };

  const handleUpdateScope = async () => {
    setIsLoading(true);
    await handleScopeUpdate({ action: MetricActionType.Update, surveyId: surveyData._id });
    dispatch(reloadSurvey(surveyData._id));
    toggle();
    hideBanner();
    setIsLoading(false);
  };

  return isShowingBanner ? (
    <>
      <BannerAlert type={'info'} handleClose={hideBanner}>
        <i className='fa-light fa-solid fa-circle-info fa-lg' color='secondary' />
        <div>Your {SURVEY.ADJECTIVE} {PACK.SINGULAR}(s) has been updated. Do you want to update your {SURVEY.SINGULAR} to include the changes?</div>
        <Button color='primary' size='md' onClick={toggle}>
          Update {SURVEY.SINGULAR}
        </Button>
        <Button color='secondary' size='md' onClick={hideBanner}>
          Ignore
        </Button>
      </BannerAlert>
      <Modal isOpen={isStakeholderOpen} toggle={toggle} backdrop='static' className='modal-md'>
        {isLoading ? (
          <>
            <ModalHeader>Scope {PACK.PLURAL} have been updated</ModalHeader>
            <ModalBody className='mt-5 mb-4'>
              <Loader relative />
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader toggle={toggle}>Scope {PACK.PLURAL} have been updated</ModalHeader>
            <ModalBody>
              <p>
                One or more scope {PACK.PLURAL} assigned to this {SURVEY.SINGULAR} has been updated. This means you may be reporting on an
                outdated version of a standard, framework or custom scope {PACK.SINGULAR}.</p>
              <p className='mb-4'>
                Please note, updating your {SURVEY.SINGULAR} scope will not erase any answers you have already submitted.
              </p>
              <SurveyGroupTable className='mb-4' displayGroupNames={displayGroupNames} />
              <div className='d-flex gap-3 align-center justify-content-end'>
                <Button color='link' className='text-ThemeDangerMedium' size='md' onClick={handleIgnoreChanges}>
                  Ignore Changes
                </Button>
                <Button color='primary' size='md' onClick={handleUpdateScope}>
                  Update ({displayGroupNames.length}) scope
                </Button>
              </div>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  ) : null;
};
