import { DashboardDivider } from '@g17eco/atoms/divider';
import { useToggle } from '@hooks/useToggle';
import classNames from 'classnames';
import React from 'react';
import { Button, Collapse } from 'reactstrap';
import { ConnectionProps, ConnectionsGroupProps, SurveyConnection, UtrvConnection } from './types';
import { getUnitText } from '@utils/units';
import { NumberScale } from '@g17eco/types/units';
import { DetailsTooltip } from './DetailsTooltip';
import { Variables } from './Variables';
import { CalculationGroupValueType } from '@g17eco/types/utrv-connections';

const getConnectionUnit = ({ connection, utr }: Pick<ConnectionProps, 'connection' | 'utr'>) => {
  if (!connection.valueListCode) {
    return getUnitText({ valueType: utr.valueType, unit: connection.unit, unitType: utr.unitType }, '');
  }

  const column = utr.valueValidation?.table?.columns.find((col) => col.code === connection.valueListCode);
  if (!column) {
    return '';
  }
  return getUnitText({ valueType: column.type, unit: connection.unit, unitType: column.unitType }, '');
};

const isSurveyConnection = (connection: UtrvConnection): connection is SurveyConnection =>
  Boolean('surveyId' in connection);

const isSelectedConnection = ({
  selectedConnection,
  connection,
}: Pick<ConnectionProps, 'selectedConnection' | 'connection'>) => {
  if (selectedConnection && isSurveyConnection(connection) && isSurveyConnection(selectedConnection)) {
    // 1 connection can have multiple survey connections so we need to check the surveyId
    return selectedConnection._id === connection._id && selectedConnection.surveyId === connection.surveyId;
  }

  return selectedConnection?._id === connection._id;
};

const Connection = (props: ConnectionProps) => {
  const {
    connection,
    name,
    utrs,
    integrationUtrs,
    selectedConnection,
    onClickConnection,
    onPopulateInput,
    initiativeId,
    utr,
    surveyScope,
  } = props;

  const [isOpen, toggle] = useToggle(false);

  const numberScale = connection.numberScale === NumberScale.Single ? '' : connection.numberScale;
  const unit = getConnectionUnit({ connection, utr });

  const isTextValueTypeConnection = connection.groupValueType === CalculationGroupValueType.Text;
  const getAnswer = () => {
    if (isTextValueTypeConnection) {
      return connection.value ?? '';
    }
    return `${connection.value} ${numberScale} ${unit}`;
  };

  const renderAnswerIndicator = () => {
    if (isTextValueTypeConnection) {
      return (
        <i
          className={classNames('fa-light text-ThemeIconSecondary ms-1', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      );
    }
    return (
      <i
        className='fa-light fa-arrow-right-to-arc ms-2 text-ThemeAccentMedium cursor-pointer'
        onClick={() => onPopulateInput(connection)}
      />
    );
  };

  const onClickAnswer = () => {
    if (isTextValueTypeConnection) {
      toggle();
    }
  };

  return (
    <div
      className={classNames('p-1', {
        'cursor-pointer': connection.valueListCode,
        'background-ThemeAccentExtralight': isSelectedConnection({ selectedConnection, connection }),
      })}
      onClick={() => onClickConnection(connection)}
    >
      {name ? (
        <label className='text-ThemeTextMedium mb-2 text-sm'>
          CONNECTION {name} <DetailsTooltip {...props} />
        </label>
      ) : null}
      <div key={connection._id}>
        <div className='d-flex justify-content-between align-items-center' onClick={onClickAnswer}>
          <span className='text-sm text-ThemeTextMedium pr-5'>Answer:</span>
          <p
            className={classNames('mb-0 text-ThemeTextDark fw-bold text-truncate', {
              'cursor-pointer': isTextValueTypeConnection,
            })}
          >
            {getAnswer()}
          </p>
          {renderAnswerIndicator()}
        </div>
        <Collapse isOpen={isOpen} className='my-2'>
          <div className='p-2 bg-white rounded'>
            <div className='w-100 d-flex justify-content-center'>
              <Button color='link' className='text-sm' onClick={() => onPopulateInput(connection)}>
                Add to metric <i className='fa-light fa-arrow-right-to-arc ms-1 text-sm' />
              </Button>
            </div>
            <div className='mt-2'>{getAnswer()}</div>
          </div>
        </Collapse>
        <Variables
          connection={connection}
          utrs={utrs}
          integrationUtrs={integrationUtrs}
          initiativeId={initiativeId}
          surveyScope={surveyScope}
        />
      </div>
    </div>
  );
};

export const ConnectionsGroup = ({
  title,
  connections,
  displayDetails,
  integrationUtrs,
  utrs,
  utr,
  selectedConnection,
  onClickConnection,
  onPopulateInput,
  initiativeId,
  surveyScope,
}: ConnectionsGroupProps) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <div className='my-2 d-flex justify-content-between align-items-center cursor-pointer' onClick={toggle}>
        <h6 className='mb-0 mr-1 text-ThemeTextLight'>{title}</h6>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        {connections.map((connection, index, connections) => {
          return (
            <React.Fragment key={connection._id}>
              {index ? <DashboardDivider className='my-3' /> : null}
              <Connection
                displayDetails={displayDetails}
                connection={connection}
                name={connections.length === 1 ? undefined : String(index + 1)}
                utrs={utrs}
                integrationUtrs={integrationUtrs}
                selectedConnection={selectedConnection}
                onClickConnection={onClickConnection}
                onPopulateInput={onPopulateInput}
                initiativeId={initiativeId}
                utr={utr}
                surveyScope={surveyScope}
              />
            </React.Fragment>
          );
        })}
      </Collapse>
    </>
  );
};
