import { UniversalTrackerPlain, UtrValueType, ValueValidation } from '@g17eco/types/universalTracker';
import { Form } from '../EditMetricModal';
import { FormGenerator, FieldProps } from '@g17eco/molecules/form';
import type { InputType } from 'reactstrap/types/lib/Input';
import { useMemo } from 'react';
import { PercentageSettingsFields } from './PercentageSettingsFields';
import { CANNOT_EDIT_NOTE } from '../constants';
import { SURVEY } from '@constants/terminology';
import { getUnitsForType, UnitTypes } from '@utils/units';
import { UnitOption } from '@features/units-currency-settings';
import { canSetNumberScale } from '../utils';

interface Props {
  formData: Form;
  isAnswered: boolean;
  onChange: (e: React.ChangeEvent<{ name: string; value: any }>) => void;
  initialData: UniversalTrackerPlain | undefined;
  setValueValidation: (value: ValueValidation) => void;
}

const getDefaultSettingsFields = (isAnswered: boolean): FieldProps[] => [
  {
    code: 'unitType',
    type: 'select' as InputType,
    disabled: isAnswered,
    label: `Value Unit (Note: this is for outputs, the input unit is determined at the ${SURVEY.SINGULAR} level)`,
    options: [
      { value: '', label: 'None' },
      { value: 'time', label: 'Time' },
      { value: 'area', label: 'Area' },
      { value: 'length', label: 'Length' },
      { value: 'mass', label: 'Mass' },
      { value: 'volume', label: 'Volume' },
      { value: 'energy', label: 'Energy' },
      { value: 'co2Emissions', label: 'CO2 Emissions' },
      { value: 'partsPer', label: 'Concentration/PartsPer' },
      { value: 'currency', label: 'Currency' },
    ],
    classes: {
      label: 'fw-bold',
    },
    note: isAnswered ? CANNOT_EDIT_NOTE : undefined,
    testId: 'metric-unit-type-select'
  },
];

const getNumberScaleField = (isAnswered: boolean, canSetNumberScale: boolean) => ({
  code: 'numberScale',
  type: 'select' as InputType,
  disabled: isAnswered || !canSetNumberScale,
  label: 'Number scale',
  options: getUnitsForType(UnitTypes.currency).map(({ abbr, singular }: UnitOption) => ({
    label: `${singular} (${abbr})`,
    value: abbr,
  })),
  classes: {
    label: 'fw-bold',
  },
  tooltip: 'Number scale cannot be set whilst answers are restricted to a value range',
  note: isAnswered ? CANNOT_EDIT_NOTE : undefined,
  testId: 'metric-number-scale-select',
});

export const ValueTypeSettingsContainer = (props: Props) => {
  const { formData, onChange, isAnswered, initialData, setValueValidation } = props;
  const canSetNumberScaleValue = canSetNumberScale(formData);
  const defaultFields = useMemo(() => getDefaultSettingsFields(isAnswered), [isAnswered]);
  const numberScaleField = useMemo(
    () => getNumberScaleField(isAnswered, canSetNumberScaleValue),
    [isAnswered, canSetNumberScaleValue]
  );

  switch (formData.valueType) {
    case UtrValueType.Percentage:
      return (
        <>
          <PercentageSettingsFields
            initialData={initialData}
            formData={formData}
            setValueValidation={setValueValidation}
          />
          <FormGenerator
            key={Number(canSetNumberScale)}
            fields={[numberScaleField]}
            form={formData}
            updateForm={onChange}
          />
        </>
      );
    case UtrValueType.Number:
      return <FormGenerator fields={[...defaultFields, numberScaleField]} form={formData} updateForm={onChange} />;
    default:
      return null;
  }
};
