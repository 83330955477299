import { Button, Modal, ModalBody, ModalHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { Toggle } from '@g17eco/molecules/toggle';
import { InsightDashboard } from '../../../types/insight-custom-dashboard';
import { Loader } from '@g17eco/atoms/loader';
import { generateUrl } from '../../util';
import { ROUTES } from '../../../constants/routes';
import { useShareInsightDashboardMutation } from '../../../api/insight-dashboards';
import { isESGDashboard } from './utils';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  dashboard: InsightDashboard;
};

const copyButtonId = 'CopyButton';

export const SharingModal = ({ isOpen, toggle, dashboard }: Props) => {
  const [shareDashboard, { isLoading }] = useShareInsightDashboardMutation();

  const share = dashboard.share?.[0];
  const sharable = Boolean(share?.enabled);

  const handleToggle = () => {
    const dashboardId = isESGDashboard(dashboard.type) ? dashboard.type : dashboard._id; // TODO [Dashboard] Remove after migration is done.
    shareDashboard({
      dashboardId,
      initiativeId: dashboard.initiativeId,
      enabled: !sharable,
    });
  };

  const className = sharable
    ? 'border-ThemeBorderDefault'
    : 'background-ThemeBgDark border-ThemeBgDark text-ThemeTextLight';

  const sharedLink = sharable
    ? `${window.location.protocol}//${window.location.host}${generateUrl(ROUTES.SHARED_CUSTOM_DASHBOARD, {
        dashboardId: dashboard._id,
        token: share?.token ?? '',
      })}`
    : '';

  const handleCopy = () => {
    navigator.clipboard.writeText(sharedLink);
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static' returnFocusAfterClose={false}>
      <ModalHeader toggle={toggle}>Share this page</ModalHeader>
      <ModalBody>
        {isLoading ? <Loader /> : null}
        <Toggle
          checked={sharable}
          onChange={handleToggle}
          label='Allow sharing'
          className={{ form: 'd-flex justify-content-end align-items-center', label: 'text-md ml-2' }}
          disabled={isLoading}
        />
        <div className={`border rounded d-flex align-items-center mt-3 px-3 py-2 ${className}`}>
          <i className='fa-light fa-globe mr-3 text-ThemeIconSecondary text-lg' /> Anyone with the link can view this
          page
        </div>
        {sharable ? (
          <div className='d-flex align-items-center mt-2 px-3 py-2 gap-3 rounded background-ThemeBgDark'>
            <i className='fa-light fa-link text-ThemeIconSecondary text-lg' />
            <div className='text-ThemeTextLight text-break'>{sharedLink}</div>
          </div>
        ) : null}
        <div className='d-flex justify-content-center mt-3'>
          <Button id={copyButtonId} disabled={!sharable} color='link' size='lg' onClick={handleCopy}>
            <i className='fa-light fa-link mr-2 text-md' /> Copy link
          </Button>
          <UncontrolledPopover placement='bottom' target={copyButtonId} trigger='focus'>
            <PopoverBody>Link is copied.</PopoverBody>
          </UncontrolledPopover>
        </div>
      </ModalBody>
    </Modal>
  );
};
