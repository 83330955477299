import {
  convertToUtrvStatuses,
  DisplayOption,
  DownloadUtrvStatus,
  getDisplaySelectOptions,
  getDisplayStatus,
  getDownloadDisplayOptions,
  getMetricStatusOptions,
  getSelectedDisplayOption,
  privacyOptions,
  SelectedGroup,
} from '@components/downloads/util/downloadReportHandler';
import { SelectFactory, SelectTypes, Option }  from '@g17eco/molecules/select/SelectFactory';
import { SimpleTooltip } from '@g17eco/molecules/simple-tooltip';
import { Form, OnChangeForm } from './types';
import { PACK } from '@constants/terminology';
import { getModulesFromScope } from '@utils/survey-scope';
import { mergeScopes } from '@utils/dataShare';
import { compareStringArrays } from '@utils/string';
import { useAppSettings } from '@hooks/app/useAppSettings';

interface Props {
  filters: Form['metricFilters'];
  onChangeForm: OnChangeForm;
  scopeModules: SelectedGroup[];
}

export const Filters = ({ filters, onChangeForm, scopeModules }: Props) => {
  const { defaultDownloadOptions } = useAppSettings();
  const metricStatusOptions = getMetricStatusOptions({
    hasAssuredOption: true,
    isDisableAssuredOption: false,
    defaultOptions: defaultDownloadOptions?.metricStatuses,
  });
  const displayOptions = getDisplaySelectOptions({
    hasInitiativeUtrs: true,
    defaultOptions: defaultDownloadOptions?.metricOverrides,
  });
  const moduleOptions: Option<string>[] = scopeModules.map((item) => ({
    value: item.code,
    label: item.name,
    searchString: `${item.name} ${item.code}`,
  }));

  const moduleCodes = scopeModules.map((s) => s.code);
  const moduleValues = getModulesFromScope(filters.scope).filter((m) => moduleCodes.includes(m));

  const status = getDisplayStatus(filters);
  const handleChangeStatus = (value: DownloadUtrvStatus) => {
    const { assuranceStatus, statuses } = convertToUtrvStatuses(value);
    onChangeForm({
      metricFilters: {
        ...filters,
        statuses,
        assuranceStatus,
      },
    });
  };

  const handleChangePrivacy = (value: string) => {
    onChangeForm({
      metricFilters: {
        ...filters,
        visibility: value,
      },
    });
  };

  const display = getSelectedDisplayOption(filters);
  const handleChangeDisplay = (value: DisplayOption) => {
    const { displayUserInput, displayMetricOverrides } = getDownloadDisplayOptions(value);
    onChangeForm({
      metricFilters: {
        ...filters,
        displayUserInput,
        displayMetricOverrides,
      },
    });
  };

  const handleChangeModule = (values: string[]) => {
    // This is called when the user click on selection so the action is add or remove
    // So only one of the addedModules or removedModules will will have elements.
    const { added: addedModules, removed: removedModules } = compareStringArrays({
      oldArray: moduleValues,
      newArray: values,
    });

    const isAdding = addedModules.length > 0;
    const action = isAdding ? 'add' : 'remove';

    const modules = isAdding ? addedModules : removedModules;
    const toChangeScopeModules = scopeModules
      .filter((s) => modules.includes(s.code))
      .map((s) => ({ code: s.code, scopeType: s.type }));

    onChangeForm({
      metricFilters: {
        ...filters,
        scope: mergeScopes(filters.scope, toChangeScopeModules, action),
      },
    });
  };

  return (
    <>
      <div className='d-flex align-items-center gap-2 mt-4'>
        <SimpleTooltip text='These are the default data filters for this report. These can be changed later when downloading your report.'>
          <i className='fa-light fa-circle-info text-ThemeIconSecondary' />
        </SimpleTooltip>
        <h6 className='m-0 fw-bold'>Data filters</h6>
      </div>
      <SelectFactory
        value={metricStatusOptions.find((v) => v.value === status)}
        className='w-100 mt-3'
        selectType={SelectTypes.SingleSelect}
        options={metricStatusOptions}
        onChange={(option) => option?.value && handleChangeStatus(option.value as DownloadUtrvStatus)}
        isSearchable={false}
      />
      <SelectFactory
        value={privacyOptions.find((v) => v.value === filters.visibility)}
        className='w-100 mt-3'
        selectType={SelectTypes.SingleSelect}
        options={privacyOptions}
        onChange={(option) => option?.value && handleChangePrivacy(option.value)}
        isSearchable={false}
      />
      <SelectFactory
        value={displayOptions.find((op) => op.value === display)}
        className='w-100 mt-3'
        selectType={SelectTypes.SingleSelect}
        options={displayOptions}
        onChange={(option) => option?.value && handleChangeDisplay(option.value)}
        isSearchable={false}
      />
      <SelectFactory
        selectType={SelectTypes.MultipleSelect}
        className='w-100 mt-3'
        options={moduleOptions}
        onChange={handleChangeModule}
        values={moduleValues}
        placeholder={`Export only selected ${PACK.PLURAL}`}
        menuPlacement='auto'
        isDisabled={moduleOptions.length === 0}
      />
    </>
  );
};
